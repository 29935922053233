import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const history = useHistory();

  useEffect(() => {
    fetchManufacturerAdmin();
  }, []);

  const fetchManufacturerAdmin = async () => {
    try {
      const response = await fetch(environment.apiUrl + "employee/all/storage");
      const fetchedUsers = await response.json();
      console.log(fetchedUsers);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching size :", error);
    }
  };

  const onDelete = (id) => {
    axios
      .delete(environment.apiUrl + "employee/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error(
          "It cannot be Deleted because it's linked with a supervisor"
        );
      });
  };
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (users != null) {
    return (
      <div className="col-12">
        <Card>
          <Card.Body>
            <div className="default-tab">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Manufacturer Admin</h4>
                  <Link to="/addManufacturerAdmin">
                    <button className="btn btn-primary">Add</button>
                  </Link>
                </div>
                <div className="card-body">
                  <div className="w-100 table-responsive">
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Serial No</th>
                            <th>Name</th>
                            <th>Company Name</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers &&
                            currentUsers.map((res, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{res?.name}</td>
                                <td>{res?.companyname}</td>
                                <td>{res?.mobileNumber}</td>
                                <td>{res?.email}</td>
                                <td>{res?.address}</td>

                                <td>
                                  <div className="btn-group" role="group">
                                    <button
                                      onClick={() => {
                                        history.push(`/editManufacturerAdmin`);
                                        localStorage.setItem("manuFacturerId", res.id);
                                      }}
                                      className="btn btn-primary"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => onDelete(res.id)}
                                      style={{ marginLeft: "5px" }}
                                      className="btn btn-danger"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <ul className="pagination justify-content-end">
                        {Array.from({
                          length: Math.ceil(users.length / usersPerPage),
                        }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              index + 1 === currentPage ? "active" : ""
                            }`}
                          >
                            <button
                              onClick={() => paginate(index + 1)}
                              className="page-link"
                              style={{ alignItems: "center" }}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Size</h4>
            <Link to="/add-size">
              <button className="btn btn-primary">Add Size</button>
            </Link>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default UserList;
