import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DatePicker } from "reactstrap-date-picker";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      attendanceData(id);
    }
  }, [id]);

  const attendanceData = async (empId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `empAttendance/myAttendance/${empId}`
      );
      const fetchedUsers = await response.json();
      console.log(fetchedUsers);
      setUsers(fetchedUsers);
      setFilteredUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching attendance data :", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSearch = async () => {
    try {
      const formattedFromDate = fromDate ? formatDateForSearch(fromDate) : "";
      const formattedToDate = toDate ? formatDateForSearch(toDate) : "";

      const dateData = {
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        empId:id
      };

      console.log("dateData", dateData);

      if (!formattedFromDate && !formattedToDate) {
        toast.error("Enter To date & From date");
      } else {
        const response = await fetch(
          environment.apiUrl + "empAttendance/date",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dateData),
          }
        );

        const res1 = await response.json();

        if (response.ok) {
          setFilteredUsers(res1);
          console.log("Filtered Users", res1);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatDateForSearch = (dateString) => {
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${year}-${month}-${day}`;
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "empAttendance/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error("It can not be Deleted because its link with supervisor");
      });
  }

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (users != null) {
    return (
      <div className="col-12">
        <Card>
          <Card.Body>
            <div className="row">
              <div className="col-12 d-flex justify-content-end">
                <Link to="/superAttendance"></Link>
              </div>
            </div>
            <div className="default-tab">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Employee Attendance </h4>
                  <Link to="/add-empAttendances">
                    {/* <button className="btn btn-primary">Mark Attendance</button> */}
                  </Link>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <h5>Date</h5>
                    <div className="form-row" style={{ display: "flex" }}>
                      <div className="col-4">
                        <DatePicker
                          placeholder="From (YYYY-MM-DD)"
                          value={fromDate}
                          onChange={(date) => setFromDate(date)}
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div className="col-4">
                        <DatePicker
                          placeholder="To (YYYY-MM-DD)"
                          value={toDate}
                          onChange={(date) => setToDate(date)}
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div className="col">
                        <button
                          className="btn btn-primary"
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 table-responsive">
                    <div>
                      <table
                        className="table table-bordered"
                        style={{ marginTop: "20px" }}
                      >
                        <thead>
                          <tr>
                            <th>Serial No</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Location Map View</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers.map((res, index) => (
                            <tr key={res.id}>
                              <td>{index + 1}</td>
                              <td>{formatDate(res.date)}</td>
                              <td>{res.time}</td>
                              <td>
                                <iframe
                                  title={`Location-${index}`}
                                  style={{ marginLeft: "10px" }}
                                  width="200"
                                  height="100"
                                  src={`https://www.google.com/maps/embed/v1/place?key=${environment.mapKey}&q=${res.lat},${res.lng}`}
                                ></iframe>
                              </td>
                              <td>
                                <button
                                  onClick={() => onDelete(res.id)}
                                  style={{ marginLeft: "5px" }}
                                  className="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* Pagination */}
                      <ul className="pagination justify-content-end">
                        {Array.from({
                          length: Math.ceil(
                            filteredUsers.length / usersPerPage
                          ),
                        }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              index + 1 === currentPage ? "active" : ""
                            }`}
                          >
                            <button
                              onClick={() => paginate(index + 1)}
                              className="page-link"
                              style={{ alignItems: "center" }}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
  // } else {
  //   return (
  //     <div className="col-12">
  //       <div className="card">
  //         <div className="card-header">
  //           <h4 className="card-title">Employee Attendance</h4>
  //           <Link to="/add-empAttendance">
  //             <button className="btn btn-primary">Mark Attendance</button>
  //           </Link>
  //         </div>
  //         <div className="card-body">
  //           <div className="w-100 table-responsive">
  //             <div></div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
};

export default UserList;
