import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { DatePicker } from "reactstrap-date-picker";
import * as moment from "moment";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const userId = localStorage.getItem("USERID");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCSHLswN-ovA89kDxZ2-pagjJaKqEkNP_8",
    libraries: ["directions"],
  });

  /////////////////////////////Add Calculator///////////////////////////

  const history= useHistory()
  
  useEffect(() => {
    attendanceData();
  }, []);

  const attendanceData = async () => {
    try {

      const response = await fetch(
        environment.apiUrl + `empAttendance/myAttendance/${userId}`
      );
      const fetchedUsers = await response.json();
      console.log(fetchedUsers);
      setUsers(fetchedUsers);
      setFilteredUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const calculateLocation = async (e) => {
    try {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        // setLat(latitude);
        // setLng(longitude);
        console.log("Latitude:", latitude);
        console.log("Longitude:", longitude);

        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${environment.mapKey}`
        );

        if (response.ok) {
          const data = await response.json();
          if (data && data.results && data.results.length > 0) {
            const formattedAddress = data.results[0].formatted_address;
            const currentDate = new Date();
            const formattedDbDate = currentDate.toISOString().split("T")[0];
            const formattedTime = currentDate.toLocaleTimeString("en-US", {
              hour12: false,
            });
            console.log(formattedAddress);

            // setDate(formattedDbDate);
            // setTime(formattedTime);
            // setLocation(formattedAddress);
            const userId = await localStorage.getItem("USERID");
            const attendanceData = {
              date: formattedDbDate,
              time: formattedTime,
              location: formattedAddress,
              employeeId: userId,
              lat: latitude,
              lng: longitude,
            };

            try {
              const response = await fetch(
                environment.apiUrl + "empAttendance/create",
                {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(attendanceData),
                }
              );
              if (response.ok) {
                toast.success("Attendance Marked");
                handleCancel();
              
              } else {
                toast.error("You have already marked your attendance");
                // setIsSubmitting(false);
              }
            } catch (error) {
              toast.error("Error at marking attendance");
              // setIsSubmitting(false);
            }
          } else {
            console.error("No results found in the Google Maps API response.");
          }
        } else {
          console.error(
            "Error response from the Google Maps API:",
            response.statusText
          );
        }
      });
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  const handleCancel = () => {
    attendanceData();
    history.push({
      pathname: "/empAttendances",
    });
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSearch = async () => {
    try {
      const formattedFromDate = fromDate ? formatDateForSearch(fromDate) : "";
      const formattedToDate = toDate ? formatDateForSearch(toDate) : "";

      const dateData = {
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        empId: userId,
      };

      console.log("dateData", dateData);

      const response = await fetch(environment.apiUrl + "empAttendance/date", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dateData),
      });

      const res1 = await response.json();

      if (response.ok) {
        setFilteredUsers(res1);
        console.log("ans", res1);
        console.log(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching data by date range:", error);
    }
  };

  const formatDateForSearch = (dateString) => {
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${year}-${month}-${day}`;
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + `empAttendance/delete/${id}`, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setFilteredUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== id)
        );
      })
      .catch((error) => {
        toast.error(
          "It cannot be Deleted because it's linked with a supervisor"
        );
      });
  }

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const mapContainerStyle = {
    width: "100%",
    height: "150px",
    borderRadius: "5px",
  };

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <Link to="/superAttendance"></Link>
            </div>
          </div>
          <div className="default-tab">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Employee Attendance</h4>
                {/* <Link to="/add-empAttendances"> */}
                  <button className="btn btn-primary" onClick={calculateLocation}>Mark Attendance</button>
                {/* </Link> */}
              </div>
              <div className="card-body">
                <div className="form-row">
                  <h5>Date</h5>
                  <div className="form-row" style={{ display: "flex" }}>
                    <div className="col-4">
                      <DatePicker
                        placeholder="From (YYYY-MM-DD)"
                        value={fromDate}
                        onChange={(date) => setFromDate(date)}
                      />
                    </div>
                    &nbsp;&nbsp;
                    <div className="col-4">
                      <DatePicker
                        placeholder="To (YYYY-MM-DD)"
                        value={toDate}
                        onChange={(date) => setToDate(date)}
                      />
                    </div>
                    &nbsp;&nbsp;
                    <div className="col">
                      <button
                        className="btn btn-primary"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-100 table-responsive">
                  <div>
                    <table
                      className="table table-bordered"
                      style={{ marginTop: "20px" }}
                    >
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Location Map View</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers.map((res, index) => (
                          <tr key={res.id}>
                            <td>{index + 1}</td>
                            <td>{formatDate(res.date)}</td>
                            <td>{res.time}</td>
                            <td>
                              <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                zoom={15}
                                center={{
                                  lat: parseFloat(res.lat),
                                  lng: parseFloat(res.lng),
                                }}
                              >
                                <Marker
                                  position={{
                                    lat: parseFloat(res.lat),
                                    lng: parseFloat(res.lng),
                                  }}
                                />
                              </GoogleMap>
                              {/* <iframe
                                title={`Location-${index}`}
                                style={{ marginLeft: "10px" }}
                                width="200"
                                height="100"
                                src={`https://www.google.com/maps/embed/v1/place?key=${environment.mapKey}&q=${res.latitude},${res.longitude}`}
                              ></iframe> */}
                            </td>
                            {/* <td>
                              <button
                                onClick={() => onDelete(res.id)}
                                style={{ marginLeft: "5px" }}
                                className="btn btn-danger"
                              >
                                Delete
                              </button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* Pagination */}
                    <ul className="pagination justify-content-end">
                      {Array.from({
                        length: Math.ceil(filteredUsers.length / usersPerPage),
                      }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            index + 1 === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            onClick={() => paginate(index + 1)}
                            className="page-link"
                            style={{ alignItems: "center" }}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
