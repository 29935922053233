import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card, Form } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { Box, Grid, Modal, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 460,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const PartyUser = () => {
  const [partyUsers, setPartyUsers] = useState([]);
  const [serialNumber, setSerialNumber] = useState(2000);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [filterValue, setFilterValue] = useState("");
  const [originalPartyUsers, setOriginalPartyUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const history = useHistory();
  const USERID = localStorage.getItem("USERID");

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = partyUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (USERID) {
      fetchPartyUsers();
    }
  }, [USERID]);

  const fetchPartyUsers = async () => {
    try {
      const response = await axios.get(
        environment.apiUrl + "party/all/" + USERID, // Use USERID for fetching party list
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
          },
        }
      );
      setPartyUsers(response.data);
      setOriginalPartyUsers(response.data);
    } catch (error) {
      console.error("Error fetching party users:", error);
    }
  };
  const findMobile = async () => {
    const mobileNumberPattern = /^\d{10}$/;
    if (!mobileNumberPattern.test(mobileNumber)) {
      toast.error(" Provide valid mobile number");
      return;
    }
    const number = {
      mobileNumber: mobileNumber,
    };
    try {
      const response = await fetch(
        environment.apiUrl + "party/getmobileNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(number),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        history.push("/allEmpParty");
        await localStorage.setItem("existingMobileNo", mobileNumber);
      } else if (responseData.message == "Mobile number already exists") {
        const confirmation = window.confirm(
          "This Party already exists, would you like to add it to your list as well."
        );
        const myPartyId = await responseData.data.id;
        const myUserTableId = await localStorage.getItem("USERID");
        if (confirmation) {
          handleClose();

          const bodyData = {
            userTableId: myUserTableId,
            partyId: myPartyId,
          };
          try {
            const response = await fetch(
              environment.apiUrl + "party/notExist/partyEmp",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(bodyData),
              }
            );

            const nonExistResponse = await response.json();

            if (response.ok) {
              toast.success("Party has been added");
              fetchPartyUsers();
            } else {
              if (nonExistResponse.message == "Party already exist") {
                toast.success("Party has been added");
                fetchPartyUsers();
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [filterValue]);

  const handleFilter = () => {
    if (filterValue.trim() === "") {
      setPartyUsers(originalPartyUsers);
    } else {
      const filteredUsers = originalPartyUsers.filter(
        (user) =>
          user.party.businessName
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          user.party.contactPerson
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          user.party.mobileNumber
            .toString()
            .toLowerCase()
            .includes(filterValue.toLowerCase())
      );
      setPartyUsers(filteredUsers);
    }
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "party/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");

        setPartyUsers((prevUsers) =>
          prevUsers.filter((user) => user.party.id !== id)
        );
      })
      .catch((error) => {
        toast.error(
          "It can not be Deleted because it's linked with a supervisor"
        );
      });
  }

  return (
    <Fragment>
      {/* <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/empParty">Party</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#"> Party List</Link>
          </li>
        </ol>
      </div> */}
      <div className="container mt-4">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, borderRadius: "10px" }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={8} style={{}}>
                <Form.Group
                  controlId="brandMarka"
                  style={{ marginTop: "20px" }}
                >
                  <Form.Control
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    placeholder="Enter Mobile Number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    type="number"
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <button
                  className="btn btn-primary"
                  style={{ margin: "10px", marginTop: "25px" }}
                  onClick={findMobile}
                >
                  Search
                </button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Party List</h4>
            {/* <Link to="/allEmpParty"> */}
            <button
              className="btn btn-primary"
              style={{ margin: "10px" }}
              onClick={() => {
                handleOpen();
                setMobileNumber("");
              }}
            >
              Add Party
            </button>
            {/* </Link> */}
          </div>
          <div className="card-body">
            <Grid container>
              <Grid
                items
                xs={12}
                sm={12}
                md={6}
                style={{ marginBottom: "10px", marginTop: "-15px" }}
              >
                <TextField
                  variant="outlined"
                  placeholder="Search..."
                  fullWidth
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
              </Grid>
            </Grid>
            <div className="w-100 table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Serial No</th>
                    <th>Party SlNo</th>
                    <th>Business Name</th>
                    <th>Contact Person</th>
                    <th>Mobile Number</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers &&
                    currentUsers.map((res, index) => (
                      <tr key={index}>
                        {" "}
                        {/* Add a key prop */}
                        <td>{index + 1}</td>
                        <td>{res.party?.slNo}</td>
                        <td>{res.party.businessName}</td>
                        <td
                          style={{
                            color: res.party.isBlacklist ? "red" : "inherit",
                            textDecoration: res.party.isBlacklist
                              ? "line-through"
                              : "none",
                          }}
                        >
                          {res.party.contactPerson}{" "}
                        </td>
                        <td>{res.party.mobileNumber} </td>
                        <td>
                          <div className="btn-group" role="group">
                            <button
                              onClick={() =>
                                history.replace("partyUser/" + res.party.id)
                              }
                              className="btn btn-primary"
                            >
                              Edit
                            </button>
                            {/* <button
                              onClick={() => onDelete(res.party.id)}
                              style={{ marginLeft: "5px" }}
                              className="btn btn-danger"
                            >
                              Delete
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <ul className="pagination justify-content-end">
                {Array.from({
                  length: Math.ceil(partyUsers.length / usersPerPage),
                }).map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      index + 1 === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      onClick={() => paginate(index + 1)}
                      className="page-link"
                      style={{ alignItems: "center" }}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PartyUser;
