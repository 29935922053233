import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { loadingToggleAction,loginAction, loginAction1,
} from '../../store/actions/AuthActions';
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// image
import logo from "../../images/newLogo_icon.jpeg";
import loginbg from "../../images/newLogo_icon.jpeg";

function Login (props) {
  const [mobileNumber, setMobileNumber] = useState('');
  //const [password, setPassword] = useState('Admin@123#');
  const [myotp, setMyotp] = useState('1234');  /* Math.floor(1000 + Math.random() * 9000) */
  const [otp, setOtp] = useState(Math.floor(100000 + Math.random() * 999999));
    let errorsObj = { mobileNumber: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
	const [status, setStatus] = useState(false);
	const history = useHistory();

    const dispatch = useDispatch();

	/* if(localStorage.getItem("USERID")!=null) {
		history.push("/dashboard")
	} */

	function onLogin1(e) {
		setStatus(true);
		e.preventDefault();
		
        dispatch(loginAction1(mobileNumber, otp, props.history));
		setStatus(false);
    }

  return (
		<div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
			<div className="login-aside text-center  d-flex flex-column flex-row-auto">
				{/* <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
					<div className="text-center mb-4 pt-5">
						<img style={{height:"50px"}} src={logo} alt="" />
						<span style={{fontWeight:"bold",fontSize:"18px"}}>Tradins</span>
					</div>
					<h3 className="mb-2">Welcome back!</h3>
					<p>Trade Confidently With Our <br />Profitable Buy & Sell Trading Alerts</p>
				</div> */}
				<div className="aside-image text-center">
				<img style={{height:"220px",marginTop:"50px"}} src={loginbg} alt="" />
				</div>
			</div>
			<div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
				<div className="d-flex justify-content-center h-100 align-items-center">
					<div className="authincation-content style-2">
					<div className="text-center  ">
						<img style={{height:"100px", borderRadius:"50%"}} src={logo} alt="" />
					</div>
						<div className="row no-gutters">
							<div className="col-xl-12 tab-content">
								<div id="sign-in" className="auth-form   form-validation">
									{props.errorMessage && (
										<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
											{props.errorMessage}
										</div>
									)}
									{props.successMessage && (
										<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
											{props.successMessage}
										</div>
									)}
									<form onSubmit={onLogin1} className="form-validate">
										<h3 className="text-center mb-4 text-black">Sign in your account</h3>
										<div className="form-group mb-3">
											<label className="mb-1"  htmlFor="val-email"><strong>Enter Mobile Number</strong></label>
											<div>
												<input type="txt" className="form-control"
													value={mobileNumber}
												   onChange={(e) => setMobileNumber(e.target.value)}
												   placeholder="Type Your Mobile Number"
												/>
											</div>
											
										</div>

										{/* <div className="form-group mb-3">
											<label className="mb-1"  htmlFor="val-email"><strong>Enter Password</strong></label>
											<div>
												<input type="password" className="form-control"
													value={password}
												   onChange={(e) => setPassword(e.target.value)}
												   placeholder="Type Your Password"
												/>
											</div>
											
										</div> */}
										{/* <div className="form-group mb-3">
											<label className="mb-1"><strong>Password</strong></label>
											<input
											  type="password"
											  className="form-control"
											  value={password}
											  placeholder="Type Your Password"
												onChange={(e) =>
													setPassword(e.target.value)
												}
											/>
											{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
										</div> */}
										{/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
											<div className="form-group mb-3">
											   <div className="custom-control custom-checkbox ml-1">
													<input type="checkbox" className="form-check-input" id="basic_checkbox_1" />
													<label className="form-check-label" htmlFor="basic_checkbox_1">Remember my preference</label>
												</div>
											</div>
										</div> */}
										<div className="text-center form-group mb-3">
											<button type='submit' className="btn btn-primary btn-block" disabled={status}>
												Submit
											</button>
										</div>
									</form>
									<div className="new-account mt-3">
										<p>Don't have an account? <Link className="text-primary" to="./page-register">Sign up</Link></p>
									</div>
									{/* <ToastContainer/> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>	
		
  );
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);
