import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import SupervisorDashboard from "./components/Dashboard/SupervisorDashboard";
import AdvisorDashboard from "./components/Dashboard/AdvisorDashboard";
import CustomerDashboard from "./components/Dashboard/CustomerDashboard";
import DashboardDark from "./components/Dashboard/DashboardDark";
import GuestList from "./components/Dashboard/GuestList";
import GuestDetails from "./components/Dashboard/GuestDetails";
import ConciergeList from "./components/Dashboard/ConciergeList";
import RoomList from "./components/Dashboard/RoomList";
import Reviews from "./components/Dashboard/Reviews";
import Task from "./components/Dashboard/Task";

/////Demo
import Theme1 from "./components/Dashboard/Demo/Theme1";
import Theme2 from "./components/Dashboard/Demo/Theme2";
import Theme3 from "./components/Dashboard/Demo/Theme3";
import Theme4 from "./components/Dashboard/Demo/Theme4";
import Theme5 from "./components/Dashboard/Demo/Theme5";
import Theme6 from "./components/Dashboard/Demo/Theme6";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
//import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";
//import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
//import WizardForm from "./components/Forms/ReduxWizard/Index";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

//User
import UserList from "./components/User/userlist";
import SuperUserList from "./components/User/superuserlist";
import AdvisorUserList from "./components/User/advisoruserlist";
import AddUser from "./components/User/adduser";
import EditUser from "./components/User/edituser";
import AddAdvisor from "./components/User/addadvisor";
import AddCustomer from "./components/User/addcustomer";
import Profile from "./components/User/profile";
import Myuser from "./components/User/myuser";

//Subscription
import SubscriptionList from "./components/Subscription/subscription";
import AddSubscription from "./components/Subscription/addsubscription";
import EditSubscription from "./components/Subscription/editsubscription";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import Otp from "./pages/Otp";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
// import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";

/// Varient
import Varient from "./components/Varient/varient";
import Addvarient from "./components/Varient/add-varient";
import editVarient from "./components/Varient/edit-varient";

/// Commodity
import Commodity from "./components/Commodity/commodity";
import AddCommodity from "./components/Commodity/add-commodity";
import CommodityPage from "./components/Commodity/commodityPage";

/// Commodity Type
import CommodityType from "./components/Commodity_Type/commodityType";
import AddCommodityType from "./components/Commodity_Type/add-commodityType";
import editCommodityType from "./components/Commodity_Type/edit-commodityType";

/// Business Type
import BusinessType from "./components/BusinessType/businessType";
import AddBusinessType from "./components/BusinessType/add-business";
import editBusinessType from "./components/BusinessType/editBusinessType";

/// Business Class
import BusinessClass from "./components/BusinessClass/businessClass";
import addBusinessClass from "./components/BusinessClass/add-businessClass";
import editBusinessClass from "./components/BusinessClass/edit-businessClass";

/// Area
import Area from "./components/Area/area";
import addArea from "./components/Area/add-area";

/// size
import Size from "./components/size/size";
import addSize from "./components/size/add-size";
import editSize from "./components/size/edit-size";

/// Quality
import Quality from "./components/Quality/quality";
import addQuality from "./components/Quality/add-quality";
import editQuality from "./components/Quality/edit-quality";

/// Product

import Product from "./components/Product/product";
import addProduct from "./components/Product/add-product";
import VarientDetails from "./components/Product/VariantDetails";

/// Unit
import Unit from "./components/Unit/unit";
import addUnit from "./components/Unit/add-unit";
import editUnit from "./components/Unit/edit-unit";

// Report
import Report from "./components/Report/report";

/// Party
import Party from "./components/Party/party";
import PartyUserDetails from "./components/Party/partyUserDetails";
import allParty from "./components/Party/allPartyPages";
import editParty from "./components/Party/editParty";
import showParty from "./components/Party/showParty"

/// Employee

import Employee from "./components/Employee/employee";
import AddEmployee from "./components/Employee/add-employee";
import EmployeePage from "./components/Employee/employeePage";

/// Product Varient Page
import ProductVarient from "./components/Product_Varient/productVarient";

/// Vehicle
import Vehicle from "./components/Vehicle/vehicle";
import addVehicle from "./components/Vehicle/add-vehicle";
import editVehicle from "./components/Vehicle/edit-vehicle";

/// Vehicle class
import VehicleClass from "./components/Vehicle_Class/vehicleClass";
import addVehicleClass from "./components/Vehicle_Class/add-vehicleClass";
import editVehicleClass from "./components/Vehicle_Class/edit-vehicleClass";

/// calculator
import Calculator from "./components/Calculator/calculator";
import addCalculator from "./components/Calculator/add-calculator";
import editCalculator from "./components/Calculator/edit-calculator";

///price Type

import PriceType from "./components/Price_Type/priceType";
import addPriceType from "./components/Price_Type/add-priceType";
import editPriceType from "./components/Price_Type/edit-priceType";

/// Selling price

import SellingPrice from "./components/Selling_Price/sellingPrice";
import addSellingPrice from "./components/Selling_Price/add-sellingPrice";
import editSellingPrice from "./components/Selling_Price/edit-sellingPrice";

/// Attendance

import EmployeeAttendance from "./components/Attendance/employeeAttendance";
import addEmployeeAttendance from "./components/Attendance/addEmployeAttendance";
import superAdminAttendance from "./components/Attendance/superAdminAttendance";

/// Imprest Balance

import imprestBalance from "./components/Balance/balance";
import addImprestBalance from "./components/Balance/add-balance";
import editImprestBalance from "./components/Balance/edit-balance";
import AddForm from "./components/Balance/addForm";

/// Payment

import Payment from "./components/payment/payment";
import EditSuperPayment from "./components/payment/EditPayment";

/// Employee imprest balance

import EmployeeBalance from "./components/empImprestBalance/imprestBalance";
import AddEmployeeBalance from "./components/empImprestBalance/add-imprestBalance";
import editImprest from "./components/empImprestBalance/edit-imprestBalance";

/// Employee req for payment
import EmployeePayment from "./components/empPayment/payment";
import AddEmployeePayment from "./components/empPayment/add-payment";
import EditPayment from "./components/empPayment/editPayment";

/// admin imprest balance
import AdminBalance from "./components/adminImprestBalance/adminBalance";
import addAdminBalance from "./components/adminImprestBalance/add-adminBalance";
import editAdminBalance from "./components/adminImprestBalance/edit-adminBalance";
import AdminAddForm from "./components/adminImprestBalance/addForm";

/// Admin request for payment

import adminPayment from "./components/adminPayment/adminPayment";
import adminEditPayment from "./components/adminPayment/adminEditPayment";

/// Emp Unit

import EmpUnit from "./components/empUnit/empUnit";
import addEmpUnit from "./components/empUnit/add-empUnit";
import editEmpUnit from "./components/empUnit/edit-empUnit";

/// Emp calculator
import EmpCalc from "./components/empCalculator/empCalc";
import AddEmpCalc from "./components/empCalculator/addEmpClac";
import EditEmpCalc from "./components/empCalculator/editEmpCalc";

/// Admin Unit

import AdminUnit from "./components/adminUnit/adminUnit";

import addAdminUnit from "./components/adminUnit/add-adminUnit";
import editAdminUnit from "./components/adminUnit/edit-adminUnit";

/// Emp attendance

import empAttendance from "./components/empAttendance/empAttendance";
import addEmpAttendance from "./components/empAttendance/add-empAttendance";

/// admin attendance

import adminAttendance from "./components/adminAttendance/adminAttendance";
import AttendanceDetails from "./components/adminAttendance/attendanceDetails";

/// Admin Party

import AdminParty from "./components/adminParty/adminParty";
import AdminPartyUser from "./components/adminParty/partyUser";
import allAdminParty from "./components/adminParty/allPartyAdmin";
import showPartyAdmin from "./components/adminParty/showPartyAdmin";

/// user profile

import UserProfile from "./components/profile/Profile";
import UpdateImage from "./components/profile/updateImage";

/// employee party page

import EmployeeParty from "./components/employeeParty/empParty";
import EditEmpparty from "./components/employeeParty/editEmpParty";
import allEmpParty from "./components/employeeParty/empAllParty";

/// expense Type

import ExpenseType from "./components/ExpenseType/expenseType";
import AddExpenseType from "./components/ExpenseType/add-expenseType";
import EditExpenseType from "./components/ExpenseType/edit-expenseType";

/// update bank

import UpdateBank from "./components/profile/bankDetails";

/// update kyc

import UpdateKyc from "./components/profile/kycDetails";

/// admin profile kyc

import adminPartyProf from "./components/profile/allPartyAdminProf";
import adminPartyKycList from "./components/profile/adminPartyKycList";
import adminPartyKyc from "./components/profile/adminPartyKyc";

/// admin profile bank

import adminPartyBankProf from "./components/profile/allAdminBankProf";
import adminPartyBankList from "./components/profile/adminPartyBankList";
import adminPartyBank from "./components/profile/adminPartyBank";

/// Cold Storage Admin
import coldStorageAdmin from "./components/ColdStorageAdmin/coldStorage";
import addColdStorageAdmin from "./components/ColdStorageAdmin/addColdStorage";
import editColdStorageAdmin from "./components/ColdStorageAdmin/editColdStorage";

///Manufacturer Admin
import manufacturerAdmin from "./components/ManufacturerAdmin/manufacturerAdmin";
import addManufacturerAdmin from "./components/ManufacturerAdmin/addManufactureAdmin";
import editManufacturerAdmin from "./components/ManufacturerAdmin/editManufacturerAdmin";


const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "supervisor-dashboard", component: SupervisorDashboard },
    { url: "advisor-dashboard", component: AdvisorDashboard },
    { url: "customer-dashboard", component: CustomerDashboard },
    { url: "dashboard-dark", component: DashboardDark },
    { url: "guest-list", component: GuestList },
    { url: "guest-details", component: GuestDetails },
    { url: "concierge-list", component: ConciergeList },
    { url: "room-list", component: RoomList },
    { url: "reviews", component: Reviews },
    { url: "task", component: Task },

    ///Demo
    { url: "primary-sidebar", component: Theme1 },
    { url: "mini-primary-sidebar", component: Theme2 },
    { url: "compact-primary-header", component: Theme3 },
    { url: "horizontal-primary-sidebar", component: Theme4 },
    { url: "horizontal-modern-sidebar", component: Theme5 },
    { url: "modern-sidebar", component: Theme6 },

    /// Apps
    { url: "#", component: AppProfile },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },
    { url: "post-details", component: PostDetails },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },
    //{ url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },

    ///Redux
    { url: "todo", component: Todo },
    //{ url: "redux-form", component: ReduxForm },
    //{ url: "redux-wizard", component: WizardForm },

    /// Widget
    { url: "widget-basic", component: Widget },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: Customers },

    /// Form
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    ///User
    { url: "user-list", component: UserList },
    { url: "super-user-list", component: SuperUserList },
    { url: "advisor-user-list", component: AdvisorUserList },
    { url: "add-user", component: AddUser },
    { url: "edit-user/:id", component: EditUser },
    { url: "add-advisor", component: AddAdvisor },
    { url: "add-customer", component: AddCustomer },
    { url: "profile", component: Profile },
    { url: "my-user", component: Myuser },

    //Subscription
    { url: "subscription-list", component: SubscriptionList },
    { url: "add-subscription", component: AddSubscription },
    { url: "edit-subscription/:id", component: EditSubscription },

    /// table
    { url: "table-filtering", component: FilteringTable },
    { url: "table-sorting", component: SortingTable },
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-otp", component: Otp },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },

    // varient
    { url: "varient", component: Varient },
    { url: "add-varient", component: Addvarient },
    { url: "add-varient/:id", component: editVarient },

    // Employee

    { url: "employee", component: Employee },
    { url: "add-employee", component: AddEmployee },
    { url: "add-employee/:id", component: EmployeePage },

    // Commodity
    { url: "commodity", component: Commodity },
    { url: "add-commodity", component: AddCommodity },
    { url: "add-commodity/:id", component: CommodityPage },

    // Commodity Type
    { url: "commodityType", component: CommodityType },
    { url: "add-commodityType", component: AddCommodityType },
    { url: "add-commodityType/:id", component: editCommodityType },

    // Business Type
    { url: "business", component: BusinessType },
    { url: "add-business", component: AddBusinessType },
    { url: "add-business/:id", component: editBusinessType },

    // Business Class

    { url: "businessClass", component: BusinessClass },
    { url: "add-businessClass", component: addBusinessClass },
    { url: "add-businessClass/:id", component: editBusinessClass },

    // Area
    { url: "area", component: Area },
    { url: "add-area", component: addArea },

    // Size
    { url: "size", component: Size },
    { url: "add-size", component: addSize },
    { url: "add-size/:id", component: editSize },

    // Quality
    { url: "quality", component: Quality },
    { url: "add-quality", component: addQuality },
    { url: "add-quality/:id", component: editQuality },

    // product
    { url: "product", component: Product },
    { url: "add-product", component: addProduct },
    { url: "varientDetails", component: VarientDetails },

    // Unit
    { url: "unit", component: Unit },
    { url: "add-unit", component: addUnit },
    { url: "add-unit/:id", component: editUnit },

    // Report
    { url: "report", component: Report },

    // Party
    { url: "party", component: Party },
    { url: "partyUser", component: PartyUserDetails },
    { url: "allParty", component: allParty },
    { url: "partyUser/:id", component: editParty },
    { url: "showParty", component: showParty },

    // Product Varient
    { url: "productVarient", component: ProductVarient },

    // Vehicle
    { url: "vehicle", component: Vehicle },
    { url: "add-vehicle", component: addVehicle },
    { url: "add-vehicle/:id", component: editVehicle },

    // Vehicle Class

    { url: "vehicleClass", component: VehicleClass },
    { url: "add-vehicleClass", component: addVehicleClass },
    { url: "add-vehicleClass/:id", component: editVehicleClass },

    // Calculator

    { url: "calculator", component: Calculator },
    { url: "add-calculator", component: addCalculator },
    { url: "add-calculator/:id", component: editCalculator },

    // Price Type

    { url: "priceType", component: PriceType },
    { url: "add-priceType", component: addPriceType },
    { url: "add-priceType/:id", component: editPriceType },

    // Selling Price

    { url: "sellingPrice", component: SellingPrice },
    { url: "add-sellingPrice", component: addSellingPrice },
    { url: "add-sellingPrice/:id", component: editSellingPrice },

    // Attendance
    { url: "empAttendance/:id", component: EmployeeAttendance },
    { url: "add-empAttendance", component: addEmployeeAttendance },
    { url: "superAttendance", component: superAdminAttendance },

    // Imprest Balance
    { url: "imprestBalance", component: imprestBalance },
    { url: "add-imprestBalance", component: addImprestBalance },
    { url: "add-imprestBalance/:id", component: editImprestBalance },
    { url: "add-imprestBalance/balance/:id", component: AddForm },

    // payment
    { url: "payment", component: Payment },
    { url: "payment/:id", component: EditSuperPayment },

    // employee imprest balance
    { url: "empBalance", component: EmployeeBalance },
    { url: "add-empBalance", component: AddEmployeeBalance },
    { url: "add-empBalance/:id", component: editImprest },

    /// employee req for payment
    { url: "empPayment", component: EmployeePayment },
    { url: "add-empPayment", component: AddEmployeePayment },
    { url: "add-empPayment/:id", component: EditPayment },

    /// admin inprest balance
    { url: "adminBalance", component: AdminBalance },
    { url: "add-adminBalance", component: addAdminBalance },
    { url: "add-adminBalance/:id", component: editAdminBalance },
    { url: "add-adminBalance/balance/:id", component: AdminAddForm },

    /// admin request for payment
    { url: "adminPayment", component: adminPayment },
    { url: "adminPayment/:id", component: adminEditPayment },

    // emp unit

    { url: "empUnit", component: EmpUnit },
    { url: "add-empUnit", component: addEmpUnit },
    { url: "add-empUnit/:id", component: editEmpUnit },

    /// emp attendance
    { url: "empAttendances", component: empAttendance },
    { url: "add-empAttendances", component: addEmpAttendance },

    // admin unit

    { url: "adminUnit", component: AdminUnit },
    { url: "add-adminUnit", component: addAdminUnit },
    { url: "add-adminUnit/:id", component: editAdminUnit },

    /// admi attendance

    { url: "adminAttendance", component: adminAttendance },
    { url: "adminAttendanceDetails/:id", component: AttendanceDetails },

    /// Admin Party page

    { url: "adminParty", component: AdminParty },
    { url: "adminPartyUser", component: AdminPartyUser },
    { url: "allAdminParty", component: allAdminParty },
    { url: "showAdminParty", component: showPartyAdmin },

    /// Profile
    { url: "userProfile/:userId", component: UserProfile },
    { url: "updateImage/:id", component: UpdateImage },

    /// employee party
    { url: "empParty", component: EmployeeParty },
    { url: "empParty/:id", component: EditEmpparty },
    { url: "allEmpParty", component: allEmpParty },

    /// Expense Type
    { url: "expenseType", component: ExpenseType },
    { url: "add-expenseType", component: AddExpenseType },
    { url: "add-expenseType/:id", component: EditExpenseType },

    // update bank
    { url: "updateBank/:id", component: UpdateBank },

    // update kyc
    { url: "updateKyc/:id", component: UpdateKyc },

    // admin party profile kyc
    { url: "adminPartyProf/:id", component: adminPartyProf },
    { url: "adminPartyKycList", component: adminPartyKycList },
    { url: "adminPartyKyc", component: adminPartyKyc },

    // admin party profile bank
    { url: "adminPartyBankProf/:id", component: adminPartyBankProf },
    { url: "adminPartyBankList", component: adminPartyBankList },
    { url: "adminPartyBank", component: adminPartyBank },

    //emp calculator
    { url: "empCalc", component: EmpCalc },
    { url: "addEmpCalc", component: AddEmpCalc },
    { url: "editEmpCalc/:id", component: EditEmpCalc },

    // Cold Storage Admin
    { url: "coldStorageAdmin", component: coldStorageAdmin },
    { url: "addColdStorageAdmin", component: addColdStorageAdmin },
    { url: "editColdStorageAdmin", component: editColdStorageAdmin },

    // Manufacturer Admin
    { url: "manufacturerAdmin", component: manufacturerAdmin },
    { url: "addManufacturerAdmin", component: addManufacturerAdmin },
    { url: "editManufacturerAdmin", component: editManufacturerAdmin },
  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      {/* <Setting /> */}
      <ScrollToTop />
    </>
  );
};

export default Markup;
