import React, { useState, useEffect } from "react";
import { Card, Tab, Nav, Col, Row, Form } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../../environments/environment";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

const UserList = () => {
  const [users, setUsers] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [areaId, setArea] = useState("");
  const [businessTypeId, setBusiness] = useState("");
  const [commodities, setCommodities] = useState([]);
  const [selectedCommodities, setSelectedCommodities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [documentNumber, setDocNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [brandMarka, setBrandMarka] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branchName, setBranchName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [UserID, setUser] = useState("");
  const [accessUser, setAccessUser] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [address, setAddress] = useState("");
  const [notes, setNote] = useState("");
  const [selectPdf, setSelectedpdf] = useState(null);
  const [image, setPartyImage] = useState(null);
  const [docImage, setDocImage] = useState(null);
  const [docImage2, setDocImage2] = useState(null);
  const [docPdf, setDocPdf] = useState(null);
  const [docTypes, setDocTypes] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const [basicSrc, setBasicSrc] = useState(null);
  const [kycSrc1, setKycSrc1] = useState(null);
  const [kycSrc2, setKycSrc2] = useState(null);
  const [kycSrc3, setKycSrc3] = useState(null);
  const [bankScr1, setBankSrc1] = useState(null);
  const [bankScr2, setBankSrc2] = useState(null);

  const [kycId, setKycId] = useState(null);
  const [bankId, setBankId] = useState(null);
  const [isBlackList, setIsBlackList] = useState(false);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [areaAddress, setAreaAddress] = useState("");

  const user = localStorage.getItem("USERTYPE");
  const admin = localStorage.getItem("ISADMIN");
  const myShowParty = sessionStorage.getItem("myParty");

  const ID = sessionStorage.getItem("areaDataId");

  useEffect(() => {
    if (ID) {
      fetchAreaData(ID);
    }
  }, [ID]);

  const fetchAreaData = async (areaId) => {
    const areaData = await fetch(environment.apiUrl + `area/${areaId}`);
    const responseData = await areaData.json();
    console.log("area all data", responseData);
    setAddress(
      responseData.area +
        ", " +
        responseData.district +
        ", " +
        responseData.state
    );
    // setAreaAddress(responseData.area);
  };

  const [open, setOpen] = useState(false);
  const [state, setState] = useState("");
  const [states, setStates] = useState([]);
  const [dialogArea, setDialogArea] = useState("");
  const [district, setDistrict] = useState("");
  const [districts, setDistricts] = useState([]);

  const accessUserOptions = [
    {
      value: "all",
      label: "All",
    },
    ...accessUser
      // .filter((user) => user.isAdmin === false)
      .map((res) => ({
        value: res.id,
        label: res.name,
      })),
  ];

  useEffect(() => {
    fetchState();
    if (state) {
      fetchCityData(state);
    }
  }, [state]);

  const fetchCityData = async (state_id) => {
    try {
      const data = await fetch(environment.apiUrl + `city/${state_id}`);
      const jsonData = await data.json();
      setDistricts(jsonData);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const fetchState = async () => {
    const response = await fetch(environment.apiUrl + "state/all");
    const jsonData = await response.json();
    console.log("state : ", jsonData);
    setStates(jsonData);
  };

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (!dialogArea) {
      toast.error("Area field is required.");
      return;
    }

    if (!district) {
      toast.error("District field is required.");
      return;
    }

    if (!state) {
      toast.error("State field is required.");
      return;
    }

    const areaData = {
      area: dialogArea,
    };

    try {
      const response = await fetch(environment.apiUrl + "area/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(areaData),
      });

      const responseData = await response.json();

      if (response.ok) {
        const areaId = responseData.id;

        const newDialogArea = {
          areaId: areaId,
          state_id: state,
          city_id: district,
        };

        try {
          const response = await fetch(
            environment.apiUrl + "dialogArea/create",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newDialogArea),
            }
          );
          if (response.ok) {
            toast.success(" Area created successfully!");
            fetchArea();
          } else {
            toast.error("Error adding area.");
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      toast.error("Error adding area");
      console.log(error);
    }

    handleClose();
  };

  useEffect(() => {
    fetchDocType();
  }, []);

  const fetchDocType = async () => {
    const data = await fetch(environment.apiUrl + "doctype/all");
    const jsonData = await data.json();
    setDocTypes(jsonData);
  };

  useEffect(() => {
    if (id) {
      fetchParyBasic(id);
    }
  }, [id]);

  const defaultImageUrl =
    "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";

  const fetchParyBasic = async (basicId) => {
    const data = await fetch(environment.apiUrl + `party/${basicId}`);
    const basicInfo = await data.json();
    console.log(basicInfo);
    setBusinessName(basicInfo.party.businessName);
    setBrandMarka(basicInfo.party.brandMarka);
    setContactPerson(basicInfo.party.contactPerson);
    setMobileNumber(basicInfo.party.mobileNumber);
    setAddress(basicInfo.party.address);
    setArea(basicInfo.party.areaId);
    setBusiness(basicInfo.party.businessTypeId);
    setPartyImage(basicInfo.party.image);
    setUser(basicInfo.party.UserID);
    setNote(basicInfo.party.notes);
    setKycId(basicInfo.party.kycId);
    setBankId(basicInfo.party.bankId);
    setIsBlackList(basicInfo.party.isBlacklist);
    let data2 = basicInfo.party.image
      ? environment.apiUrl + "party/files/" + basicInfo.party.image
      : null;
    console.log(data2);
    setBasicSrc(data2);

    let i = basicInfo.commodities.length;
    let j = 0;
    let itemAdded = [];
    for (let index = 0; index < basicInfo.commodities.length; index++) {
      const element = basicInfo.commodities[index];
      itemAdded.push({
        value: element.commodity.id,
        label: element.commodity.commodity,
      });
      j++;
      if (i == j) {
        setSelectedCommodities(itemAdded);
      }
    }

    let _i = basicInfo.employees.length;
    let _j = 0;
    let _itemAdded = [];
    for (let index = 0; index < basicInfo.employees.length; index++) {
      const element = basicInfo.employees[index];
      _itemAdded.push({
        value: element.userTable.id,
        label: element.userTable.name,
      });
      _j++;
      if (_i == _j) {
        setSelectedUsers(_itemAdded);
      }
    }

    setDocumentType(basicInfo.kyc.documentTypeId);
    setDocNumber(basicInfo.kyc.documentNumber);
    setDocImage(basicInfo.kyc.imageOfDocument);
    setDocImage2(basicInfo.kyc.image2);
    setDocPdf(basicInfo.kyc.documentPDF);
    let kycdata1 = basicInfo.kyc.imageOfDocument
      ? environment.apiUrl + "kyc/files/" + basicInfo.kyc.imageOfDocument
      : null;
    console.log("image data", kycdata1);
    let kycdata2 = basicInfo.kyc.image2
      ? environment.apiUrl + "kyc/files/" + basicInfo.kyc.image2
      : null;
    console.log("image data", kycdata2);
    let kycdata3 =
      environment.apiUrl + "kyc/files/" + basicInfo.kyc.documentPDF;
    setKycSrc1(kycdata1);
    setKycSrc2(kycdata2);
    setKycSrc3(kycdata3);

    setName(basicInfo.bank.name);
    setAccNumber(basicInfo.bank.accountNumber);
    setIfsc(basicInfo.bank.ifsc);
    setBranchName(basicInfo.bank.branchName);
    setSelectedImage(basicInfo.bank.imageOfCancelledCheque);
    setSelectedpdf(basicInfo.bank.documentOfCancelledCheque);
    let bankdata1 = basicInfo.bank.imageOfCancelledCheque
      ? environment.apiUrl +
        "bank/files/" +
        basicInfo.bank.imageOfCancelledCheque
      : null;
    let bankdata2 =
      environment.apiUrl +
      "bank/files/" +
      basicInfo.bank.documentOfCancelledCheque;
    setBankSrc1(bankdata1);
    setBankSrc2(bankdata2);
  };

  const onBasicUpdate = async () => {
    /* console.log({
      employees: selectedUsers,
      commodities: selectedCommodities
    }); */
    const formDataBasic = new FormData();
    formDataBasic.append("businessName", businessName);
    formDataBasic.append("brandMarka", brandMarka);
    formDataBasic.append("contactPerson", contactPerson);
    formDataBasic.append("mobileNumber", mobileNumber);
    formDataBasic.append("address", address);
    formDataBasic.append("areaId", areaId);
    formDataBasic.append("businessTypeId", businessTypeId);
    formDataBasic.append("commodityId", 53);
    formDataBasic.append("UserID", UserID);
    formDataBasic.append("notes", notes);
    formDataBasic.append("isBlacklist", isBlackList);
    formDataBasic.append("image", image);
    // formDataBasic.append("kycId", kycId);
    // formDataBasic.append("bankId", bankId);
    formDataBasic.append("commodities", JSON.stringify(selectedCommodities));
    formDataBasic.append("employees", JSON.stringify(selectedUsers));

    try {
      const response = await fetch(environment.apiUrl + `party/update/${id}`, {
        method: "PUT",
        headers: {},
        body: formDataBasic,
      });

      const responseData2 = await response.json();

      if (response.ok) {
        toast.success("Party updated successfully!");
        if (user === "SuperAdministrator" && myShowParty) {
          history.push("/showParty");
          sessionStorage.removeItem("myParty");
        } else if (user === "SuperAdministrator" && !myShowParty) {
          history.push("/partyUser");
        } else if (user !== "SuperAdministrator" && admin === "true" && !myShowParty ) {
          history.push("/adminPartyUser");
        }else if (user !== "SuperAdministrator" && admin === "true" && myShowParty ) {
          history.push("/showAdminParty");
          sessionStorage.removeItem("myParty");
        } 
         else {
          history.push("/empParty");
        }
        fetchParyBasic(id);
        // handleCancel();
      } else {
        if (responseData2 && responseData2.message) {
          toast.error(responseData2.message);
          console.log(responseData2.message);
        } else {
          toast.error("Error Updateing Party. Please try again.");
        }
      }
    } catch (error) {
      console.log(error);

      toast.error("Error Updateing Party. Please try again");
    }
  };

  const onKycUpdate = async () => {
    const formData = new FormData();
    formData.append("documentTypeId", documentType);
    formData.append("documentNumber", documentNumber);

    if (docImage) {
      formData.append("imageOfDocument", docImage);
    }
    if (docImage2) {
      formData.append("image2", docImage2);
    }

    if (docPdf) {
      formData.append("documentPDF", docPdf);
    }

    try {
      const response = await fetch(environment.apiUrl + `kyc/update/${kycId}`, {
        method: "PUT",
        headers: {},
        body: formData,
      });

      const responseData2 = await response.json();

      if (response.ok) {
        toast.success("Kyc updated successfully!");
        if (user === "SuperAdministrator" && myShowParty) {
          history.push("/showParty");
          sessionStorage.removeItem("myParty");
        } else if (user === "SuperAdministrator" && !myShowParty) {
          history.push("/partyUser");
        } else if (user !== "SuperAdministrator" && admin === "true" && !myShowParty ) {
          history.push("/adminPartyUser");
        }else if (user !== "SuperAdministrator" && admin === "true" && myShowParty ) {
          history.push("/showAdminParty");
          sessionStorage.removeItem("myParty");
        } 
         else {
          history.push("/empParty");
        }
        fetchParyBasic(id);
      } else {
        if (responseData2 && responseData2.message) {
          toast.error(responseData2.message);
        } else {
          toast.error("Error adding Party. Please try again.");
          console.log();
        }
      }
    } catch (error) {
      console.log(error);

      toast.error("Error adding Party. Please try again");
    }
  };

  const handleShowPartyOnBank = async () => {
    history.push("/showParty");
    sessionStorage.removeItem("showParty");
  };

  const onBankUpdate = async () => {
    console.log(bankId);
    console.log({
      name: name,
      accountNumber: accNumber,
      ifsc: ifsc,
      branchName: branchName,
      imageOfCancelledCheque: selectedImage,
      documentOfCancelledCheque: selectPdf,
    });
    const formDataBank = new FormData();
    formDataBank.append("name", name);
    formDataBank.append("accountNumber", accNumber);
    formDataBank.append("ifsc", ifsc);
    formDataBank.append("branchName", branchName);

    if (selectedImage) {
      formDataBank.append("imageOfCancelledCheque", selectedImage);
    }
    if (selectPdf) {
      formDataBank.append("documentOfCancelledCheque", selectPdf);
    }

    try {
      const response = await fetch(
        environment.apiUrl + `bank/update/${bankId}`,
        {
          method: "PUT",
          headers: {},
          body: formDataBank,
        }
      );

      const responseData2 = await response.json();

      if (response.ok) {
        toast.success("Bank updated successfully!");

        if (user === "SuperAdministrator" && myShowParty) {
          history.push("/showParty");
          sessionStorage.removeItem("myParty");
        } else if (user === "SuperAdministrator" && !myShowParty) {
          history.push("/partyUser");
        } else if (user !== "SuperAdministrator" && admin === "true" && !myShowParty ) {
          history.push("/adminPartyUser");
        }else if (user !== "SuperAdministrator" && admin === "true" && myShowParty ) {
          history.push("/showAdminParty");
          sessionStorage.removeItem("myParty");
        } 
         else {
          history.push("/empParty");
        }
        fetchParyBasic(id);
      } else {
        if (responseData2 && responseData2.message) {
          toast.error(responseData2.message);
        } else {
          toast.error("Error adding bank. Please try again.");
          console.log();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedImage(imageFile);
  };

  const handlepdfUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedpdf(imageFile);
  };

  const handlePartyImage = (e) => {
    const imageFile = e.target.files[0];
    setPartyImage(imageFile);
  };

  const handleDocImage = (e) => {
    const imageFile = e.target.files[0];
    setDocImage(imageFile);
  };

  const handleDocImage2 = (e) => {
    const imageFile = e.target.files[0];
    setDocImage2(imageFile);
  };

  const handlePdf = (e) => {
    const imageFile = e.target.files[0];
    setDocPdf(imageFile);
  };

  // const formData = new FormData();

  // if (selectedImage) {
  //   formData.append("image", selectedImage);
  // }

  // Define tabData before usage
  const tabData = [
    {
      name: "Basic Info",
      icon: "user",
    },
    {
      name: "KYC",
      icon: "pen",
    },
    {
      name: "Bank Account Details",
      icon: "bank",
    },
  ];

  useEffect(() => {
    fetchAllEmp();
  }, []);

  const fetchAllEmp = async () => {
    const data = await fetch(environment.apiUrl + "employee/all_emp");
    const jsonData = await data.json();
    setAccessUser(jsonData);
  };

  useEffect(() => {
    fetchCommodity();
    fetchArea();
    fetchBusinessType();
  }, []);

  const fetchCommodity = async () => {
    const data = await fetch(environment.apiUrl + "commodity/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setCommodities(jsonData);
  };

  const fetchArea = async () => {
    const data = await fetch(environment.apiUrl + "area/getAreas");
    const jsonData = await data.json();
    console.log(jsonData);
    setAreas(jsonData);
  };

  const fetchBusinessType = async () => {
    const data = await fetch(environment.apiUrl + "businessType/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setBusinessTypes(jsonData);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(environment.apiUrl + "auth/authors", {
          headers: {
            Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
          },
        });
        setUsers(response.data.authors);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    fetchData();
  }, []);

  const handleNext = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };

  const handleBack = () => {
    setActiveTab((prevTab) => prevTab - 1);
  };

  const isFirstTab = activeTab === 0;
  const isLastTab = activeTab === tabData.length - 1;

  const addUser = () => {
    alert("Selected");
  };

  /****************** Basic Details ************************** */
  const renderBasicInfoTab = () => {
    const commodityOptions = commodities.map((commodity) => ({
      value: commodity.id,
      label: commodity.commodity,
    }));

    const handleCommodityChange = (selectedOptions) => {
      setSelectedCommodities(selectedOptions);
    };

    return (
      <div
        onKeyDown={(e) => {
          // Prevent dialog from opening when Enter key is pressed in the container
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="businessName">
                <Form.Label>Business Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  placeholder="Enter Business name..."
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="brandMarka">
                <Form.Label>Brand Marka</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={brandMarka}
                  onChange={(e) => setBrandMarka(e.target.value)}
                  placeholder="Enter brand Marka..."
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="contactPersonName">
                <Form.Label>
                  Contact Person Name
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                  placeholder="Enter contact person name ..."
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="mobileNumber">
                <Form.Label>
                  Mobile Number
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder="Enter mobile number..."
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="area">
                <Form.Label>
                  Area
                  <span className="text-danger">*</span>
                </Form.Label>

                <div
                  className="col-lg-8"
                  style={{ display: "flex" }}
                  onKeyDown={(e) => {
                    // Prevent dialog from opening when Enter key is pressed in the container
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                >
                  <select
                    className="form-control"
                    id="val-role"
                    name="val-role"
                    value={areaId}
                    onChange={(e) => {
                      setArea(e.target.value);
                      sessionStorage.setItem("areaDataId", e.target.value);
                    }}
                  >
                    <option value="Select Option">Select Option</option>
                    {areas.map((res) => (
                      <option value={res.id} key={res.id}>
                        {res.area}
                        {", "}
                        {res?.district}
                        {", "}
                        {res?.state}
                      </option>
                    ))}
                  </select>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleClickOpen}
                  >
                    Add area
                  </button>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Add Area</DialogTitle>
                    <DialogContent>
                      <div>
                        <Form.Label>
                          State
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Autocomplete
                          options={states
                            .filter((s) => s.status === 1)
                            .sort((a, b) =>
                              a.state_name.localeCompare(b.state_name)
                            )}
                          getOptionLabel={(option) => option.state_name}
                          value={states.find((s) => s.state_id === state)}
                          onChange={(e, newValue) =>
                            setState(newValue ? newValue.state_id : "")
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" />
                          )}
                        />
                      </div>

                      <div style={{ marginTop: "10px" }}>
                        <Form.Label style={{ marginTop: "10px" }}>
                          District
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Autocomplete
                          options={districts
                            .filter((s) => s.status === 1)
                            .sort((a, b) =>
                              a.city_name.localeCompare(b.city_name)
                            )}
                          getOptionLabel={(option) => option.city_name}
                          value={districts.find((d) => d.city_id === district)}
                          onChange={(e, newValue) =>
                            setDistrict(newValue ? newValue.city_id : "")
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" />
                          )}
                        />
                      </div>
                      <div>
                        <TextField
                          margin="dense"
                          id="area"
                          label="Area"
                          type="text"
                          fullWidth
                          variant="standard"
                          value={dialogArea}
                          onChange={(e) => setDialogArea(e.target.value)}
                          style={{ marginBottom: "10px" }}
                        />
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </Form.Group>
            </Col>
            <>
              <Col md={6}>
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>

                  <ReactGoogleAutocomplete
                    apiKey={environment.mapKey}
                    types={["(regions)"]}
                    className="form-control"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </>
          </Row>

          <Row className="mb-3 mt-3">
            <Col md={9}>
              <Form.Group controlId="businessType">
                <Form.Label>
                  Business Type
                  <span className="text-danger">*</span>
                </Form.Label>

                <div className="col-lg-8">
                  <select
                    className="form-control"
                    id="val-role"
                    name="val-role"
                    value={businessTypeId}
                    onChange={(e) => setBusiness(e.target.value)}
                  >
                    <option value="Select Option">Select Option</option>
                    {businessTypes
                      .filter((businessType) => businessType.isActive)
                      .map((res) => (
                        <option value={res.id} key={res.id}>
                          {res.businessType}
                        </option>
                      ))}
                  </select>
                </div>
              </Form.Group>
            </Col>
            <Col md={4} style={{ marginTop: "10px" }}>
              <Form.Group controlId="image">
                <Form.Label>Image</Form.Label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handlePartyImage}
                />
              </Form.Group>
            </Col>
            <div className="col-4 mt-3">
              {basicSrc !== null ? (
                <img
                  src={basicSrc}
                  // alt="Image 1"
                  style={{
                    width: "120px",
                    height: "80px",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <img
                  src={defaultImageUrl}
                  alt="Default Image"
                  style={{
                    width: "120px",
                    height: "80px",
                    borderRadius: "10px",
                  }}
                />
              )}
            </div>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="dealInCommodity">
                <Form.Label>Deal in Commodity</Form.Label>
                <Select
                  isMulti
                  options={commodityOptions}
                  value={selectedCommodities}
                  onChange={handleCommodityChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Access to Users</Form.Label>
                <span className="text-danger">*</span>

                <Select
                  isMulti
                  options={accessUserOptions}
                  value={selectAllUsers ? accessUserOptions : selectedUsers}
                  onChange={(selectedOptions) => {
                    if (
                      selectedOptions.some((option) => option.value === "all")
                    ) {
                      setSelectAllUsers(true);
                      setSelectedUsers(accessUserOptions);
                    } else {
                      setSelectAllUsers(false);
                      setSelectedUsers(selectedOptions);
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3 ">
            <Col md={6}>
              <Form.Group controlId="note">
                <Form.Label>Note</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={notes}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Enter address..."
                />
              </Form.Group>
            </Col>
            <Col md={6} className="d-flex " style={{ marginTop: "28px" }}>
              <label className="col-lg-2 col-form-label" htmlFor="val-is-admin">
                Is Blacklist
                <span className="text-danger"></span>
              </label>

              <div className="col-lg-4">
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="isBlackList"
                      value="true"
                      checked={isBlackList}
                      onChange={() => setIsBlackList(true)}
                      required
                    />
                    Yes
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="isBlackList"
                      value="false"
                      checked={!isBlackList}
                      onChange={() => setIsBlackList(false)}
                      required
                    />
                    No
                  </label>
                </div>
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                marginTop: "10px",
                display: isFirstTab ? "none" : "inline-block",
              }}
              onClick={handleBack}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className={`btn btn-secondary`}
              style={{ marginTop: "10px" }}
              onClick={() => {
                onBasicUpdate();
              }}
            >
              Update
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className={`btn btn-${isLastTab ? "primary" : "secondary"}`}
              style={{
                marginTop: "10px",
                display: isLastTab ? "none" : "inline-block",
              }}
              onClick={handleNext}
            >
              {isLastTab ? "Submit" : "Next"}
            </button>
          </div>
        </Form>
        {/* <button onClick={()=>{onSubmit1()}}>submit</button> */}
      </div>
    );
  };

  /***************** KYC Tab ******************** */

  const handleCancel = () => {
    // history.push({
    //   pathname: "/party",
    // });

    {
      user === "SuperAdministrator"
        ? history.push("/party")
        : user != "SuperAdministrator" && admin === "true"
        ? history.push("/adminParty")
        : history.push("/empParty");
    }
  };

  const renderKYCTab = () => {
    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentType">
                <Form.Label>Type of Document</Form.Label>
                <span className="text-danger">*</span>
                <div className="col-lg-8">
                  <select
                    className="form-control"
                    id="val-role"
                    name="val-role"
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                  >
                    <option value="Select Option">Select Option</option>
                    {docTypes.map((res) => (
                      <option value={res.id}>{res.docName}</option>
                    ))}
                  </select>
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="documentNo">
                <Form.Label>Document Number</Form.Label>
                <span className="text-danger">*</span>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={documentNumber}
                    onChange={(e) => setDocNumber(e.target.value)}
                    placeholder="Enter Document Number..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docImage">
                    <Form.Label>Document Image</Form.Label>
                    <span className="text-danger">*</span>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handleDocImage}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  {kycSrc1 !== null ? (
                    <img
                      src={kycSrc1}
                      // alt="Image 1"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <img
                      src={defaultImageUrl}
                      alt="Default Image"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docImage2">
                    <Form.Label>Document Image 2</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handleDocImage2}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  {kycSrc2 !== null ? (
                    <img
                      src={kycSrc2}
                      // alt="Image 1"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <img
                      src={defaultImageUrl}
                      alt="Default Image"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-4">
              <Form.Group controlId="docPdf">
                <Form.Label>Document PDF</Form.Label>
                <input
                  type="file"
                  className="form-control"
                  accept="application/pdf"
                  onChange={handlePdf}
                />
              </Form.Group>
            </div>
            <div className="col-4" style={{ marginTop: "40px" }}>
              <a target="_blank" href={kycSrc3}>
                View Document as Pdf
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className={`btn btn-secondary`}
              style={{ marginTop: "10px" }}
              onClick={() => {
                onKycUpdate();
              }}
            >
              Update
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              style={{ marginTop: "10px" }}
              onClick={handleBack}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className={`btn btn-${isLastTab ? "primary" : "secondary"}`}
              style={{
                marginTop: "10px",
                display: isLastTab ? "none" : "inline-block",
              }}
              onClick={handleNext}
            >
              {isLastTab ? "Submit" : "Next"}
            </button>
          </div>
        </Form>
      </div>
    );
  };

  /********************* Bank Account details *********************** */

  const renderBankTab = () => {
    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentType">
                <Form.Label>Name</Form.Label>
                <span className="text-danger">*</span>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name..."
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentNo">
                <Form.Label>Account Number</Form.Label>
                <span className="text-danger">*</span>
                <div className="col-lg-8">
                  <input
                    type="number"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={accNumber}
                    onChange={(e) => setAccNumber(parseInt(e.target.value))}
                    placeholder="Enter account Number..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="documentNo">
                <Form.Label>IFSC Code</Form.Label>
                <span className="text-danger">*</span>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={ifsc}
                    onChange={(e) => setIfsc(e.target.value)}
                    placeholder="Enter ifsc code..."
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="documentType">
                <Form.Label>Branch Name</Form.Label>
                <span className="text-danger">*</span>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    placeholder="Enter branch name..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docImage">
                    <Form.Label> Image Of cancel cheque</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  {bankScr1 !== null ? (
                    <img
                      src={bankScr1}
                      // alt="Image 1"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <img
                      src={defaultImageUrl}
                      alt="Default Image"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docPdf">
                    <Form.Label>Document PDF</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="application/pdf"
                      onChange={handlepdfUpload}
                    />
                  </Form.Group>
                </div>
                <div className="col-4" style={{ marginTop: "40px" }}>
                  <a target="_blank" href={bankScr2}>
                    View Document as Pdf
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-danger"
            style={{ marginTop: "10px" }}
            onClick={handleCancel}
          >
            Cancel
          </button>
          &nbsp;&nbsp;
          <button
            // type="submit"
            className={"btn btn-secondary"}
            style={{ marginTop: "10px" }}
            onClick={() => {
              onBankUpdate();
            }}
          >
            Update
          </button>
        </div>
      </div>
    );
  };

  const renderTabContent = (tabName) => {
    if (tabName === "Basic Info") {
      return renderBasicInfoTab();
    } else if (tabName === "KYC") {
      return renderKYCTab();
    } else if (tabName === "Bank Account Details") {
      return renderBankTab();
    }
  };

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="card-header">
            <h1 className="card-title">Edit Party</h1>
          </div>
          <div className="default-tab">
            <Tab.Container
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={i}>
                      <i className={`la la-${data.icon} me-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={i} key={i}>
                    {renderTabContent(data.name)}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
