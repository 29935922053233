import React, { useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import { Dropdown, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 460,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [emp, setEmp] = useState([]);
  const [show, setShow] = useState(false);
  const [name, setName] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [accessUser, setAccessUser] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [originalPartyUsers, setOriginalPartyUsers] = useState([]);
  //////////////////user select Modal///////////////////////////////////////////////
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [mobileNumber, setMobileNumber] = useState("");
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: "/productVarient",
    });
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  useEffect(() => {
    fetchAllEmp();
  }, []);

  const fetchAllEmp = async () => {
    const data = await fetch(environment.apiUrl + "employee/all_emp");
    const jsonData = await data.json();
    setAccessUser(jsonData);
    setOriginalPartyUsers(jsonData);
  };

  useEffect(() => {
    handleFilter();
  }, [filterValue]);

  const handleFilter = () => {
    if (filterValue.trim() === "") {
      setAccessUser(originalPartyUsers);
    } else {
      const filteredUsers = originalPartyUsers.filter((user) =>
        user.name.toLowerCase().includes(filterValue.toLowerCase())
      );
      setAccessUser(filteredUsers);
    }
  };

  const findMobile = async () => {
    const mobileNumberPattern = /^\d{10}$/;
    if (!mobileNumberPattern.test(mobileNumber)) {
      toast.error(" Provide valid mobile number");
      return;
    }
    const number = {
      mobileNumber: mobileNumber,
    };
    try {
      const response = await fetch(
        environment.apiUrl + "party/getmobileNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(number),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        history.push("/allParty");
        await localStorage.setItem("existingMobileNo", mobileNumber);
      } else if (responseData.message == "Mobile number already exists") {
        const confirmation = window.confirm(
          "This Party already exists, would you like to add it to your list as well."
        );
        if (confirmation) {
          handleClose();
          // handleOpen1();
          const myPartyId = await responseData.data.id;
          const myUserId = await localStorage.getItem("USERID");
          const bodyData = {
            userTableId: myUserId,
            partyId: myPartyId,
          };
          try {
            const response = await fetch(
              environment.apiUrl + "party/notExist/partyEmp",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(bodyData),
              }
            );

            const nonExistResponse = await response.json();

            if (response.ok) {
              toast.success("Party has been added");
              history.push("/showParty");
            } else {
              if (nonExistResponse.message == "Party already exist") {
                toast.success("Party has been added");
                history.push("/showParty");
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addNotExistParty = async (userTableId) => {
    const myPartyId = await localStorage.getItem("nonExistPartyId");
    const bodyData = {
      userTableId: userTableId,
      partyId: myPartyId,
    };
    try {
      const response = await fetch(
        environment.apiUrl + "party/notExist/partyEmp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );

      const nonExistResponse = await response.json();

      if (response.ok) {
        toast.success("Party has been added");
        history.push("/partyUser");
      } else {
        if (nonExistResponse.message == "Party already exist") {
          toast.success("Party has been added");
          history.push("/partyUser");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAdmin = async () => {
    try {
      const response = await fetch(environment.apiUrl + "employee/all_admin");
      const fetchedUsers = await response.json();
      console.log(fetchedUsers);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching employee  :", error);
    }
  };

  function onShow(item) {
    console.log(item);
    axios
      .get(environment.apiUrl + "employee/my_emp/" + item.roleId, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        console.log(response);
        setEmp(response.data);
        setName(item.role.roleName);
        setShow(true);
      })
      .catch((error) => {
        toast.error(
          "It can not be Deleted because it's linked with a supervisor"
        );
      });
  }

  function onHide() {
    setShow(false);
    setEmp([]);
    setName(null);
  }

  function onPartyShow(id) {
    sessionStorage.setItem("employeeIdForPartyList", id);
    history.push("/partyUser");
  }

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "quality/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error(
          "It can not be Deleted because it's linked with a supervisor"
        );
      });
  }

  const adminUsers = users.filter((user) => user.isAdmin);

  return (
    <div className="container mt-4">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, borderRadius: "10px" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} style={{}}>
              <Form.Group controlId="brandMarka" style={{ marginTop: "20px" }}>
                <Form.Control
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  placeholder="Enter Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  type="number"
                />
              </Form.Group>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <button
                className="btn btn-primary"
                style={{ margin: "10px", marginTop: "25px" }}
                onClick={findMobile}
              >
                Search
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            borderRadius: "10px",
            maxHeight: "500px",
            overflowY: "scroll",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} style={{}}>
              <Form.Group controlId="brandMarka" style={{ marginTop: "20px" }}>
                <Form.Label style={{ textAlign: "center", fontSize: "15px" }}>
                  Select user to add it to your list as well
                </Form.Label>
              </Form.Group>
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: "-10px" }}>
              <Form.Group controlId="brandMarka">
                <Form.Control
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  placeholder="Search..."
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
              </Form.Group>
            </Grid>
            {accessUser
              .filter((item) => item.isAdmin == false)
              .map((user, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  key={index}
                  style={{ marginTop: "-10px" }}
                >
                  <Card
                    className="mb-3"
                    card-with-shadow
                    style={{ backgroundColor: "#f0f0f0" }}
                  >
                    <Card.Header>
                      <h4
                        className="card-title"
                        style={{ textAlign: "center", fontSize: "12px" }}
                      >
                        {user.name}
                      </h4>
                    </Card.Header>
                    <Card.Body>
                      <button
                        onClick={() => {
                          addNotExistParty(user.id);
                        }}
                        className="btn btn-primary btn-full-width"
                        style={{ marginTop: "-10px" }}
                      >
                        Select
                      </button>
                    </Card.Body>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Modal>
      <div className="row">
        <div className="col-12">
          {show == false ? (
            <h2
              className="mb-4"
              style={{ textAlign: "center", paddingRight: "30px" }}
            >
              Admins
            </h2>
          ) : (
            <div>
              <div className="row page-titles mx-0">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to="/party">Admins</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">All Employee List</Link>
                  </li>
                </ol>
              </div>
              <h2
                className="mb-4"
                style={{ textAlign: "center", paddingRight: "30px" }}
              >
                {name}{" "}
                <span style={{ fontSize: "14px" }}>(All Employee List)</span>
              </h2>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          {show == false ? (
            // <Link to="/allParty">
            <>
              <button
                className="btn btn-primary"
                style={{ margin: "10px" }}
                onClick={() => {
                  history.push("/showParty");
                  sessionStorage.setItem("myParty", "showParty")
                }}
              >
                Show Party
              </button>

              <button
                className="btn btn-primary"
                style={{ margin: "10px" }}
                onClick={() => {
                  handleOpen();
                  setMobileNumber("");
                }}
              >
                Add Party
              </button>
            </>
          ) : (
            // </Link>
            <button
              onClick={() => onHide()}
              className="btn btn-primary"
              style={{ margin: "10px" }}
            >
              Back
            </button>
          )}
        </div>
      </div>

      {show == false ? (
        <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
          {users.map((user, index) => (
            <div className="col-md-4" key={index}>
              <Card className="mb-3">
                <Card.Header>
                  <h4 className="card-title">{user.name}</h4>
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    <div className="col-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap" }}>
                          {user.role.roleName}
                        </span>
                        <button
                          onClick={() => onShow(user)}
                          className="btn btn-primary"
                          style={{ marginBottom: "10px" }}
                        >
                          Show Employees
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {emp.map((user, index) => (
            <div className="col-md-4" key={index}>
              <Card className="mb-3">
                <Card.Header>
                  <h4 className="card-title">{user.name}</h4>
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    <div className="col-6">
                      <ul className="list-unstyled">
                        <li>{user.role.roleName}</li>
                      </ul>
                    </div>
                    <div
                      className="col-6"
                      style={{ textAlign: "right", marginTop: "-10px" }}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          onPartyShow(user.id);
                        }}
                      >
                        Show Party
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserList;
