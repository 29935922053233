import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const history = useHistory();

  useEffect(() => {
    fetchVarient();
  }, []);

  const fetchVarient = async () => {
    const commodityId = await sessionStorage.getItem("commodityId");
    try {
      const response = await fetch(
        environment.apiUrl + `product/commodity/varient/${commodityId}`
      );
      const fetchedUsers = await response.json();
      console.log(fetchedUsers);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching size :", error);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (users != null) {
    return (
      <div className="col-12">
        <Card>
          <Card.Body>
            <div className="default-tab">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Varient Details</h4>
                  <Link to="/add-size">
                    <button
                      className="btn btn-primary"
                      onClick={() => history.goBack()}
                    >
                      Back
                    </button>
                  </Link>
                </div>
                <div className="card-body">
                  <div className="w-100 table-responsive">
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Serial No</th>
                            <th>Image Of Variant</th>
                            <th>Commodity-Variant</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers &&
                            currentUsers.map((res, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    history.push({
                                      pathname: "/productVarient",
                                    });
                                    sessionStorage.setItem(
                                      "varientId",
                                      res.varient?.id
                                    );
                                  }}
                                >
                                  {res.varient.image ? (
                                    <img
                                      src={
                                        environment.apiUrl +
                                        "varient/files/" +
                                        res.varient.image
                                      }
                                      style={{ height: "90px", width: "90px" }}
                                    />
                                  ) : (
                                    <img
                                      src="https://cdn.vectorstock.com/i/500p/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg"
                                      style={{ height: "90px", width: "90px" }}
                                    />
                                  )}
                                </td>
                                <td
                                style={{ cursor: "pointer" }}
                                  onClick={() => {

                                    history.push({
                                      pathname: "/productVarient",
                                    });
                                    sessionStorage.setItem(
                                      "varientId",
                                      res.varient?.id
                                    );
                                  }}
                                >
                                  {res?.commodity?.commodity}
                                  {"-"}
                                  {res?.varient?.varient}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <ul className="pagination justify-content-end">
                        {Array.from({
                          length: Math.ceil(users.length / usersPerPage),
                        }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              index + 1 === currentPage ? "active" : ""
                            }`}
                          >
                            <button
                              onClick={() => paginate(index + 1)}
                              className="page-link"
                              style={{ alignItems: "center" }}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Size</h4>
            <Link to="/add-size">
              <button className="btn btn-primary">Add Size</button>
            </Link>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default UserList;
