import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(9);
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: "/productVarient",
    });
  };

  function onCommodity(id) {
    sessionStorage.setItem("commodityId", id);
    history.push({
      pathname: "/varientDetails",
    });
  }

  useEffect(() => {
    fetchImage();
  }, []);

  const fetchImage = async () => {
    try {
      const response = await fetch(environment.apiUrl + "commodity/all1");
      const fetchedUsers = await response.json();
      console.log(fetchedUsers);

      let i = fetchedUsers.length;
      let j = 0;
      let itemAdded = [];

      for (let index = 0; index < fetchedUsers.length; index++) {
        const element = fetchedUsers[index];

        if (element.image != null) {
          let data1 = environment.apiUrl + "commodity/files/" + element.image;
          itemAdded.push({
            id: element.id,
            commodity: element.commodity,
            image: data1,
          });
          j++;
          if (i == j) {
            setUsers(itemAdded);
          }
        } else {
          j++;
          if (i == j) {
            setUsers(itemAdded);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching commodity images :", error);
    }
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "quality/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error("It can not be Deleted because its link with supervisor");
      });
  }

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Product</h4>
          <Link to="/add-product">
            <button className="btn btn-primary">Add Product</button>
          </Link>
        </div>
        <div className="card-body">
          <div className="w-100 table-responsive">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: "5px",
                justifyContent: "center",
              }}
            >
              {currentUsers.map((user, index) => (
                <div
                  key={index}
                  style={{
                    marginRight: "20px",
                    textAlign: "center",
                    marginBottom: "5px",
                  }}
                >
                  <img
                    src={user.image}
                    style={{
                      width: "200px",
                      height: "200px",
                    }}
                  />
                  <div>
                    <h5
                      onClick={() => onCommodity(user.id)}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      {user.commodity}
                    </h5>
                  </div>
                </div>
              ))}
            </div>
            <ul className="pagination justify-content-end">
              {Array.from({
                length: Math.ceil(users.length / usersPerPage),
              }).map((_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    index + 1 === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    onClick={() => paginate(index + 1)}
                    className="page-link"
                    style={{ alignItems: "center" }}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
  //   }
};

export default UserList;
