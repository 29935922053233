import React, { useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import { Dropdown, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Box, Grid, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 460,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState(localStorage.getItem("USERNAME"));
  const [roleId, setRoleId] = useState(localStorage.getItem("ROLEID"));
  const [emp, setEmp] = useState([]);
  const [show, setShow] = useState(false);
  const [name, setName] = useState(null);
  //////////////////search Modal///////////////////////////////////////////////
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: "/productVarient",
    });
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  const findMobile = async () => {
    const mobileNumberPattern = /^\d{10}$/;
    if (!mobileNumberPattern.test(mobileNumber)) {
      toast.error(" Provide valid mobile number");
      return;
    }
    const number = {
      mobileNumber: mobileNumber,
    };
    try {
      const response = await fetch(
        environment.apiUrl + "party/getmobileNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(number),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        history.push("/allAdminParty");
        await localStorage.setItem("existingMobileNo", mobileNumber);
      } else if (responseData.message == "Mobile number already exists") {
        // const myUserTableId = await localStorage.getItem(
        //   "employeeIdForPartyList"
        // );
        // await localStorage.setItem("nonExistPartyId", responseData.data.id);
        const confirmation = window.confirm(
          "This Party already exists, would you like to add it to your list as well."
        );
        if (confirmation) {
          handleClose();
          const myPartyId = responseData.data.id;
          const myUserId = await localStorage.getItem("USERID");
          const bodyData = {
            userTableId: myUserId,
            partyId: myPartyId,
          };
          try {
            const response = await fetch(
              environment.apiUrl + "party/notExist/partyEmp",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(bodyData),
              }
            );

            const nonExistResponse = await response.json();

            if (response.ok) {
              toast.success("Party has been added");
              history.push("/showAdminParty");
            } else {
              if (nonExistResponse.message == "Party already exist") {
                toast.success("Party has been added");
                history.push("/showAdminParty");
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAdmin = async () => {
    try {
      const response = await fetch(
        environment.apiUrl + "employee/role/" + roleId
      );
      const fetchedUsers = await response.json();
      console.log(fetchedUsers);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching employee  :", error);
    }
  };

  function onShow(item) {
    console.log(item);
    axios
      .get(environment.apiUrl + "employee/my_emp/" + item.roleId, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        console.log(response);
        setEmp(response.data);
        setName(item.role.roleName);
        setShow(true);
      })
      .catch((error) => {
        toast.error(
          "It can not be Deleted because it's linked with a supervisor"
        );
      });
  }

  function onHide() {
    setShow(false);
    setEmp([]);
    setName(null);
  }

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "quality/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error(
          "It can not be Deleted because it's linked with a supervisor"
        );
      });
  }

  //   const adminUsers = users.filter((user) => user.isAdmin);

  function onPartyShow(id) {
    sessionStorage.setItem("employeeIdForPartyList", id);
    history.push("/adminPartyUser");
  }

  return (
    <div className="container mt-4">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, borderRadius: "10px" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} style={{}}>
              <Form.Group controlId="brandMarka" style={{ marginTop: "20px" }}>
                <Form.Control
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  placeholder="Enter Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  type="number"
                />
              </Form.Group>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <button
                className="btn btn-primary"
                style={{ margin: "10px", marginTop: "25px" }}
                onClick={findMobile}
              >
                Search
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          {/* <Link to="/allAdminParty"> */}
          <>
            {" "}
            <button
              className="btn btn-primary"
              style={{ margin: "10px" }}
              onClick={() => {
                history.push("/showAdminParty");
                sessionStorage.setItem("myParty", "showParty");
              }}
            >
              Show Party
            </button>
            <button
              className="btn btn-primary"
              style={{ margin: "10px" }}
              onClick={() => {
                handleOpen();
                setMobileNumber("");
              }}
            >
              Add Party
            </button>
          </>
          {/* </Link> */}
        </div>
        <div className="col-md-12">
          <h2 style={{ textAlign: "center" }}>{userName}</h2>
        </div>
      </div>

      <div className="row">
        {users.map((user, index) => (
          <div className="col-md-4" key={index}>
            <Card className="mb-3">
              <Card.Header>
                <h4 className="card-title">{user.name}</h4>
              </Card.Header>
              <Card.Body>
                <div className="row">
                  <div className="col-4">
                    <ul className="list-unstyled">
                      <li>{user.role.roleName}</li>
                    </ul>
                  </div>
                  <div className="col-8" style={{ marginTop: "-15px" }}>
                    <button
                      onClick={() => {
                        onPartyShow(user.id);
                      }}
                      className="btn btn-primary"
                      style={{ margin: "10px" }}
                    >
                      View Party
                    </button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserList;
