import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const history = useHistory();
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    // fetchQuality();
    fetchProductByVarient();
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const data = await fetch(environment.apiUrl + "product/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setProductData(jsonData);
  };

  const fetchProductByVarient = async () => {
    const varientId = await sessionStorage.getItem("varientId");
    const myCommodityId = await sessionStorage.getItem("commodityId");
    const sendData = {
      commodityId: myCommodityId,
    };
    try {
      const data = await fetch(
        environment.apiUrl + `product/varient/product/all/${varientId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(sendData),
        }
      );
      const jsonData = await data.json();
      console.log(jsonData);
      setUsers(jsonData);
    } catch (error) {
      console.log(error);
    }
  };
  // const fetchQuality = async () => {
  //   let commodityId = sessionStorage.getItem("commodityId");
  //   try {
  //     const response = await fetch(
  //       environment.apiUrl + "product/all1/" + commodityId
  //     );
  //     const fetchedUsers = await response.json();
  //     console.log(fetchedUsers);

  //     let i = fetchedUsers.length;
  //     let j = 0;
  //     let itemAdded = [];

  //     for (let index = 0; index < fetchedUsers.length; index++) {
  //       const element = fetchedUsers[index];

  //       if (element.varient.image != null) {
  //         let data1 =
  //           environment.apiUrl + "varient/files/" + element.varient.image;
  //           console.log(data1)
  //         itemAdded.push({
  //           varient: element?.varient?.varient,
  //           quality: element?.quality?.quality,
  //           size: element?.size?.size,
  //           unit: element?.unit?.unit,
  //           newUnit: element?.newUnit,
  //           packSize: element?.packSize,
  //           image: data1,
  //         });
  //         j++;
  //         if (i == j) {
  //           setUsers(itemAdded);
  //         }
  //       } else {
  //         j++;
  //         if (i == j) {
  //           setUsers(itemAdded);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching quality :", error);
  //   }
  // };

  /* useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axios.get(environment.apiUrl + "account/authors", {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN")
        }
      })
        .then(response => 
        setUsers(response.data.authors)
      );
      // ...
    }
    fetchData();
  }, []); */

  // axios.get(environment.apiUrl + "account/authors", {
  //   headers: {
  //     Authorization: "bearer " + localStorage.getItem("MYTOKEN")
  //   }
  // })
  //   .then(response =>
  //   setUsers(response.data.authors)
  // );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const defaultImageUrl =
    "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";

  if (users != null) {
    return (
      <div className="col-12">
        <div className="row page-titles mx-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/product">Product</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">Product Varient</Link>
            </li>
          </ol>
        </div>

        {productData.length > 0 ? (
          <Card>
            <Card.Body>
              <div className="default-tab">
                <div className="card">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                      {
                        // <Link to="/product">
                        <button
                          className="btn btn-primary"
                          style={{ margin: "10px" }}
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          Back
                        </button>
                        // </Link>
                      }
                    </div>
                  </div>
                  <div className="card-header">
                    <h4 className="card-title">Product Variant</h4>
                    <Link to="/add-quality">
                      {/* <button className="btn btn-primary">
                      Add Quality
                    </button> */}
                    </Link>
                  </div>
                  <div className="card-body">
                    <div className="w-100 table-responsive">
                      <div>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Serial No</th>
                              <th>Image of Product </th>
                              <th>Variant</th>
                              <th>Quality</th>
                              <th>Size</th>
                              <th>Unit</th>
                              {/* <th>Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {currentUsers.map((res, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  {res.image != null ? (
                                    <img
                                      src={
                                        environment.apiUrl +
                                        "product/files/" +
                                        res.image
                                      }
                                      style={{
                                        width: "150px",
                                        height: "100px",
                                        padding: "5px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="https://cdn.vectorstock.com/i/500p/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg"
                                      style={{
                                        width: "150px",
                                        height: "100px",
                                        padding: "5px",
                                      }}
                                    />
                                  )}
                                </td>
                                <td>{res?.varient?.varient}</td>
                                <td>{res.quality?.quality}</td>
                                <td>{res.size?.size}</td>
                                <td>{res.newUnit}</td>

                                {/* <td>
                                <button
                                  onClick={() =>
                                    history.push({
                                      pathname: `/add-quality/`+res.id,
                                      state: res,
                                    })
                                  }
                                  className="btn btn-primary"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() => onDelete(res.id)}
                                  style={{ marginLeft: "5px" }}
                                  className="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <ul className="pagination justify-content-end">
                          {Array.from({
                            length: Math.ceil(users.length / usersPerPage),
                          }).map((_, index) => (
                            <li
                              key={index}
                              className={`page-item ${
                                index + 1 === currentPage ? "active" : ""
                              }`}
                            >
                              <button
                                onClick={() => paginate(index + 1)}
                                className="page-link"
                                style={{ alignItems: "center" }}
                              >
                                {index + 1}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        ) : (
          <h2 style={{ textAlign: "center" }}>No Product is Available</h2>
        )}
      </div>
    );
  } else {
    return (
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Product Varient</h4>
            <Link to="/add-quality">
              {/* <button className="btn btn-primary">Add Quality</button> */}
            </Link>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive"></div>
          </div>
        </div>
      </div>
    );
  }
};

export default UserList;
