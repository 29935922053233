import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { useEffect } from "react";

function JQueryValidation(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [terminate, setTerminate] = useState(false);
  const history = useHistory();

  const handleCancel = () => {
    history.push({
      pathname: "/manufacturerAdmin",
    });
  };

  const onSubmitStorage = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Name is required.");
      setIsSubmitting(false);
      return;
    }
    if (!mobileNumber) {
      toast.error("Mobile Number is required.");
      setIsSubmitting(false);
      return;
    }

    const mobileNumberPattern = /^\d{10}$/;
    if (!mobileNumberPattern.test(mobileNumber)) {
      toast.error(" Provide valid mobile number");
      setIsSubmitting(false);
      return;
    }

   
    if (!address) {
      toast.error("Address is required.");
      setIsSubmitting(false);
      return;
    }

    if (!companyName) {
      toast.error("Companay Name is required.");
      setIsSubmitting(false);
      return;
    }

    const manufacturerData = {
      name: name,
      mobileNumber: mobileNumber,
      email: email,
      address: address,
      companyname: companyName,
      terminate: terminate,
    };
    const myUserTypeId = await localStorage.getItem("USERTYPEID");

    console.log("manufacturerData", manufacturerData);

    console.log("myUserTypeId", myUserTypeId);
    try {
      const response = await fetch(
        environment.apiUrl +
          `employee/manufacturer/admin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(manufacturerData),
        }
      );

      if (response.ok) {
        toast.success("Manufacturer Admin data added successfully");
        history.push("/manufacturerAdmin");
        setIsSubmitting(true);
      } else {
        toast.error("Error add manufacturer admin data");
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("Error add manufacturer admin data");
      setIsSubmitting(false);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/manufacturerAdmin">Manufacturer Admin</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Add Manufacturer Admin</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Manufacturer Admin</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  // onSubmit={onSubmitStorage}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter Your Name"
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Mobile Number
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            placeholder="Enter Mobile Number"
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Email
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter Your Email"
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Address
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Enter Your Address"
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Company Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Enter Comapany Name"
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Terminate
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isImported"
                                value="true"
                                checked={terminate}
                                onChange={() => setTerminate(true)}
                                required
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isImported"
                                value="false"
                                checked={!terminate}
                                onChange={() => setTerminate(false)}
                                required
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                            onClick={onSubmitStorage}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
