import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import { Button } from "reactstrap";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [clickDestination, setClickDestination] = useState(false);
  const [originalCalcCopy, setOriginalCalcCopy] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  ///////////////////////////////////////add calulator data/////////////////////////////////////////////////////
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [distance, setDistance] = useState("");
  const [vehicleNames, setVehicleNames] = useState([]);
  const [showDistance, setShowDistnace] = useState("");
  const [showOrigin, setShowOrigin] = useState("");
  const [showDestination, setShowDestination] = useState("");
  const [first80, setFirst80] = useState("");
  const [perKm, setPerKm] = useState("");
  const [roundTrip, setRoundTrip] = useState(0);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [orgPlaceId, setOrgPlaceId] = useState("");
  const [destPlaceId, setDestPlaceId] = useState("");
  //////////////////////////////modal///////////////////////////////////////////////////////////////////////////
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [myplaceId, setPlaceId] = useState(null);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    calculateDistance();
  }, [origin, destination, showOrigin, showDestination]);

  const calculateDistance = () => {
    if (origin == null && destination == null) {
      return;
    }
    setOrgPlaceId(origin?.value?.place_id);
    setDestPlaceId(destination?.value?.place_id);

    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [
          {
            placeId: origin ? origin?.value?.place_id : myplaceId?.orgPlaceId,
          },
        ],
        destinations: [
          {
            placeId: destination
              ? destination?.value?.place_id
              : myplaceId?.destPlaceId,
          },
        ],
        travelMode: "DRIVING",
        avoidHighways: false,
        avoidTolls: false,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      },
      (response, status) => {
        if (status === "OK") {
          const result = response.rows[0].elements[0];
          if (result.status === "OK") {
            const distanceInMeters = result.distance.value;
            const distanceInKilometers = (distanceInMeters / 1000).toFixed(2);

            setDistance(distanceInKilometers);
            localStorage.setItem("distanceCal", distanceInKilometers);
          } else {
            toast.error("Error calculating distance.");
          }
        } else {
          toast.error("Error calculating distance.");
        }
      }
    );
  };
  useEffect(() => {
    fetchCalc();
    fetchCalcData();
  }, []);

  const fetchSingleClacData = async (id) => {
    try {
      const response = await fetch(environment.apiUrl + `calculator/${id}`);
      const calc = await response.json();

      setPlaceId(calc);
    } catch (error) {
      console.error("Error fetching calc:", error);
    }
  };

  const fetchCalc = async () => {
    try {
      const response = await fetch(
        environment.apiUrl + "calculator/origin/all"
      );
      const calc = await response.json();
      console.log(calc);
      setUsers(calc);
      setOriginalCalcCopy(calc);
    } catch (error) {
      console.error("Error fetching calc:", error);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [filterValue]);

  const handleFilter = () => {
    if (filterValue.trim() === "") {
      setUsers(originalCalcCopy);
    } else {
      const searchValue = filterValue.toLowerCase();

      const filteredUsers = originalCalcCopy.flatMap((user) => {
        const originMatches = user.origin.toLowerCase().includes(searchValue);

        if (originMatches) {
          return [user];
        }

        const matchingDestinations = user.destinations.filter((res) =>
          res?.destination.toLowerCase().includes(searchValue)
        );

        if (matchingDestinations.length > 0) {
          return [
            {
              ...user,
              destinations: matchingDestinations,
            },
          ];
        }

        return [];
      });

      setUsers(filteredUsers);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const deleteCalculator = async () => {
    const destId = await localStorage.getItem("destId");
    try {
      const findCalc = await fetch(
        environment.apiUrl + `calculator/delete/${destId}`,
        {
          method: "DELETE",
        }
      );

      if (findCalc) {
        toast.success("Deleted Successfully");
        fetchCalc();
        fetchCalcData();
        setEdit(false);
        setClickDestination(false);
      } else {
        toast.error("Error at delete");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onAddUser = async () => {
    setIsSubmitting(true);

    if (!origin || !destination) {
      toast.error("Origin and Destination are required.");
      setIsSubmitting(false);
      return;
    }

    const CalcData = {
      origin: origin?.label,
      destination: destination?.label,
      distance: distance,
      orgPlaceId: orgPlaceId,
      destPlaceId: destPlaceId,
    };

    console.log("CalcData", CalcData);

    try {
      const response = await fetch(
        environment.apiUrl + "calculator/create/web",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(CalcData),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        toast.success(" Created successfully!");
        setOpen(false);
        fetchCalc();
        history.push({
          pathname: "/calculator",
        });
      } else if (responseData.message == "Already Exist") {
        toast.error("Origin and Destination already exist");
      } else {
        toast.error("Error adding calculator.");
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("Error adding calculator");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onAddUserUpdate = async () => {
    const CalcData = {
      origin: origin == null ? showOrigin : origin.label,
      destination: destination == null ? showDestination : destination.label,
      distance: distance,

      orgPlaceId: orgPlaceId ? orgPlaceId : localStorage.getItem("orgPlaceId"),
      destPlaceId: destPlaceId
        ? destPlaceId
        : localStorage.getItem("destPlaceId"),
    };

    console.log("CalcData", CalcData);
    const destId = await localStorage.getItem("destId");

    try {
      const response = await fetch(
        environment.apiUrl + `calculator/update/${destId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(CalcData),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        toast.success(" Updated successfully!");
        setOpen(false);
        setClickDestination(false);
        setEdit(false);
        fetchCalc();
        history.push({
          pathname: "/calculator",
        });
        setOpenModal(false);
      } else if (responseData.message == "Already Exist") {
        toast.error("Origin and Destination already exist");
      } else {
        toast.error("Error update calculator.");
        // setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("Error update calculator");
      // setIsSubmitting(false);
    } finally {
      // setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchVehicleName();
  }, []);

  const fetchVehicleName = async () => {
    const data = await fetch(environment.apiUrl + "vehicle/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setVehicleNames(jsonData);
  };

  const fetchCalcData = async (calcId) => {
    const data = await fetch(environment.apiUrl + `calculator/${calcId}`);
    const calcData = await data.json();
    console.log("calcData", calcData);
    setShowOrigin(calcData.origin);
    setShowDestination(calcData.destination);
    setShowDistnace(calcData.distance);
  };

  const fetchVehicleData = async (vehicleId) => {
    try {
      const response = await fetch(environment.apiUrl + `vehicle/${vehicleId}`);
      const vehicleData = await response.json();

      setFirst80(vehicleData.first80kmCharges);
      setPerKm(vehicleData.perkmCharges);
    } catch (error) {
      console.log("Error fetching vehicle data:", error);
    }
  };

  const calculateFreightCalcDistance = async (vehicleId) => {
    try {
      const response = await fetch(environment.apiUrl + `vehicle/${vehicleId}`);
      const vehicleData = await response.json();

      let roundTripAmount = 0;
      let newShowDistance = await parseFloat(showDistance);
      let newFirst80 = vehicleData.first80kmCharges;
      let newPerKm = vehicleData.perkmCharges;
      let minusAmount = await parseFloat(80);
      if (newShowDistance * 2 >= minusAmount) {
        roundTripAmount =
          newFirst80 + (newShowDistance * 2 - minusAmount) * newPerKm;

        console.log("roundTripAmount", roundTripAmount);
        const formatAmount = await parseFloat(roundTripAmount).toFixed(2);
        setRoundTrip(formatAmount);
      } else {
        roundTripAmount = newFirst80;
        const formatAmount = await parseFloat(roundTripAmount).toFixed(2);
        setRoundTrip(formatAmount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DrawerList = (
    <Box sx={{ width: 650 }} role="presentation">
      <Grid container style={{ marginTop: "10px" }}>
        <Grid item xs={2} sm={2} md={0.8}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} sm={10} md={8}>
          <p style={{ fontSize: "16px", marginTop: "5px" }}>
            Freight Calculator Form
          </p>
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          md={3}
          style={{ display: "flex", marginTop: "5px" }}
        >
          <Button
            color="danger"
            outline
            style={{ height: "40px" }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;
          <button
            className="btn btn-primary"
            style={{ height: "40px", width: "70px" }}
            onClick={onAddUser}
          >
            Save
          </button>
        </Grid>
        <Grid item xs={12} sm={12} md={1}></Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          style={{
            marginTop: "100px",
            margin: "20px",
            justifyContent: "center",
          }}
        >
          <label>
            Origin
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <GooglePlacesAutocomplete
              apiKey={environment.mapKey}
              types={["address"]}
              selectProps={{
                onChange: setOrigin,
              }}
            />
          </div>
          <div className="col-lg-1"></div>
        </Grid>
        <Grid item xs={12} sm={12} md={1}></Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          style={{
            marginTop: "100px",
            margin: "20px",
            justifyContent: "center",
          }}
        >
          <label>
            Destination
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <GooglePlacesAutocomplete
              apiKey={environment.mapKey}
              types={["address"]}
              selectProps={{
                onChange: setDestination,
              }}
              // onChange={orglatlong}
            />
          </div>
          <div className="col-lg-1"></div>
        </Grid>
        <Grid item xs={12} sm={12} md={1}></Grid>
      </Grid>
    </Box>
  );

  return (
    <div className="col-12">
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        {DrawerList}
      </Drawer>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Edit Your Origin And Destination
          </Typography>{" "}
          <Grid container style={{ marginTop: "40px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                marginTop: "-20px",
                justifyContent: "center",
              }}
            >
              <label>
                Origin
                <span className="text-danger">*</span>
              </label>

              <div className="col-lg-12">
                <GooglePlacesAutocomplete
                  apiKey={environment.mapKey}
                  types={["address"]}
                  selectProps={{
                    onChange: setOrigin,
                    defaultInputValue: showOrigin,
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                marginTop: "20px",

                justifyContent: "center",
              }}
            >
              <label>
                Destination
                <span className="text-danger">*</span>
              </label>

              <div className="col-lg-12">
                <GooglePlacesAutocomplete
                  apiKey={environment.mapKey}
                  types={["address"]}
                  selectProps={{
                    onChange: setDestination,
                    defaultInputValue: showDestination,
                  }}
                  // onChange={orglatlong}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: "center", width: "100%", marginTop: "10px" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onAddUserUpdate}
                fullWidth
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Card
        style={{
          padding: "3px",
          margin: "0px",
          // overflowY: "scroll",
          // scrollbarWidth: "none",
          // maxHeight: "500px",
        }}
      >
        <Grid container style={{ marginTop: "10px" }}>
          <Grid item xs={12} sm={12} md={3}></Grid>
          <Grid item xs={12} sm={12} md={5} style={{ textAlign: "center" }}>
            <TextField
              variant="outlined"
              placeholder="Search..."
              fullWidth
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}></Grid>
        </Grid>
        {clickDestination ? (
          <Card.Body style={{ padding: "0px", marginTop: "10px" }}>
            <div
              style={{
                borderTopWidth: "1px",
                borderTopColor: "gray",
                borderTopStyle: "solid",
              }}
            ></div>

            <Grid container spacing={0} style={{ marginTop: "10px" }}>
              <Grid item xs={5.5} sm={5.5} md={5.5} style={{}}>
                <div className="default-tab">
                  {/* <div className="card"> */}
                  <div className="card-header" style={{ marginTop: "-20px" }}>
                    <h4 className="card-title">Freight Calculator</h4>
                    {/* <Link to="/addEmpCalc"> */}
                    <button
                      className="btn btn-primary"
                      onClick={toggleDrawer(true)}
                    >
                      Add Freight Calculator
                    </button>
                    {/* </Link> */}
                  </div>
                  <div>
                    <div className="w-100 table-responsive">
                      <table
                        className="table table-bordered"
                        style={{ width: "100%" }}
                      >
                        <tbody>
                          {currentUsers.map((res, index) => (
                            <tr key={res.id}>
                              <td style={{ padding: "0px" }}>
                                <p
                                  style={{
                                    fontSize: "13.3px",
                                    backgroundColor: "#f0f0f0",
                                    padding: "10px",
                                  }}
                                >
                                  {res.origin}
                                </p>
                                {res?.destinations.map((item) => (
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      marginBottom: "-10px",
                                      padding: "10px",
                                      marginTop: "-15px",
                                      cursor: "pointer",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      setClickDestination(true);
                                      setEdit(false);
                                      fetchCalcData(item.id);
                                      fetchSingleClacData(item.id);
                                      localStorage.setItem("destId", item.id);
                                      localStorage.setItem(
                                        "orgPlaceId",
                                        item.orgPlaceId
                                      );
                                      localStorage.setItem(
                                        "destPlaceId",
                                        item.destPlaceId
                                      );
                                    }}
                                  >
                                    <p>{item.destination}</p>
                                    <p>{item.distance}</p>
                                  </div>
                                ))}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </Grid>
              <div
                style={{
                  marginTop: "-9px",
                  borderLeftWidth: "1px",
                  borderLeftColor: "gray",
                  borderLeftStyle: "solid",
                  marginLeft: "-1px",
                  // marginRight:"3px"
                  // height:"100%"
                }}
              ></div>
              <Grid item xs={6.5} sm={6.5} md={6.5}>
                <div>
                  <div className="card-header" style={{ marginTop: "-25px" }}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8.9}
                        style={{ marginTop: "10px" }}
                      >
                        <h4 className="card-title">{showDistance}</h4>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3.1}
                        style={{ display: "flex" }}
                      >
                        <IconButton
                          onClick={() => {
                            setOpen(false);
                            deleteCalculator();
                          }}
                          style={{ fontSize: "25px" }}
                        >
                          <DeleteIcon style={{ fontSize: "25px" }} />
                        </IconButton>
                        <IconButton onClick={() => setClickDestination(false)}>
                          <CloseIcon />
                        </IconButton>
                        {/* {edit ? (
                          <button
                            className="btn btn-primary"
                            style={{ height: "40px", width: "auto" }}
                            onClick={onAddUserUpdate}
                          >
                            Save
                          </button>
                        ) : ( */}
                        <button
                          className="btn btn-primary"
                          style={{ height: "40px", width: "auto" }}
                          onClick={() => {
                            setEdit(true);
                            handleOpenModal();
                            calculateDistance();
                            // calculateDistanceEdit();
                          }}
                        >
                          Edit
                        </button>
                        {/* )} */}
                      </Grid>
                    </Grid>
                  </div>

                  <Grid container spacing={1} style={{ padding: "5px" }}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ textAlign: "center" }}
                    >
                      <p style={{ fontSize: "13px", marginTop: "10px" }}>
                        Round Trip Cost
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "bold",
                          marginTop: "-10px",
                        }}
                      >
                        ₹{roundTrip}
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ textAlign: "center", marginTop: "-10px" }}
                    >
                      <p style={{ fontSize: "13px" }}>Vehicle Make</p>
                    </Grid>
                    {vehicleNames.map((vehicle) => (
                      <Grid
                        item
                        // xs={12}
                        // sm={12}
                        // md={3}
                        style={{ textAlign: "center" }}
                      >
                        <p
                          style={{
                            backgroundColor:
                              selectedVehicleId === vehicle.id
                                ? "#34BADA"
                                : "#34CEC1",
                            color: "white",
                            padding: "10px",
                            cursor: "pointer",
                            borderRadius: "10px",
                          }}
                          onClick={() => {
                            fetchVehicleData(vehicle.id);
                            setSelectedVehicleId(vehicle.id);
                            calculateFreightCalcDistance(vehicle.id);
                          }}
                        >
                          {vehicle?.vehicleName}
                        </p>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ textAlign: "center" }}
                  >
                    <p style={{ fontSize: "13px" }}>Total KM</p>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        marginTop: "-10px",
                      }}
                    >
                      {showDistance}
                    </p>
                  </Grid>
                  {/* {edit == false ? ( */}
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ textAlign: "center" }}
                    >
                      <p style={{ fontSize: "13px" }}>Origin</p>
                      <p
                        style={{
                          fontSize: "13px",
                          // fontWeight: "bold",
                          color: "black",
                          marginTop: "-10px",
                        }}
                      >
                        {showOrigin}{" "}
                        <span>
                          <LocationOnIcon />
                        </span>
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ textAlign: "center" }}
                    >
                      <p style={{ fontSize: "13px" }}>Destination</p>
                      <p
                        style={{
                          fontSize: "13px",
                          color: "black",
                          marginTop: "-10px",
                        }}
                      >
                        {showDestination}{" "}
                        <span>
                          <LocationOnIcon />
                        </span>
                      </p>
                    </Grid>
                  </>
                  {/* ) : (
                    <>
                      {" "}
                      <Grid container style={{ margin: "40px" }}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            marginTop: "-20px",
                            justifyContent: "center",
                          }}
                        >
                          <label>
                            Origin
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-1"></div>
                          <div className="col-lg-10">
                            <GooglePlacesAutocomplete
                              apiKey={environment.mapKey}
                              types={["address"]}
                              selectProps={{
                                onChange: setOrigin,
                                defaultInputValue: showOrigin,
                              }}
                            />
                          </div>
                          <div className="col-lg-1"></div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            marginTop: "20px",

                            justifyContent: "center",
                          }}
                        >
                          <label>
                            Destination
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-1"></div>
                          <div className="col-lg-10">
                            <GooglePlacesAutocomplete
                              apiKey={environment.mapKey}
                              types={["address"]}
                              selectProps={{
                                onChange: setDestination,
                                defaultInputValue: showDestination,
                              }}
                              // onChange={orglatlong}
                            />
                          </div>
                          <div className="col-lg-1"></div>
                        </Grid>
                      </Grid>
                    </>
                  )} */}
                </div>
              </Grid>
            </Grid>
          </Card.Body>
        ) : (
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Card.Body>
                <div className="default-tab">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Freight Calculator</h4>
                      {/* <Link to="/addEmpCalc"> */}
                      <button
                        className="btn btn-primary"
                        onClick={toggleDrawer(true)}
                      >
                        Add Freight Calculator
                      </button>
                      {/* </Link> */}
                    </div>
                    <div className="card-body">
                      <div className="w-100 table-responsive">
                        <table className="table table-bordered">
                          <tbody>
                            {currentUsers.map((res, index) => (
                              <tr key={res.id}>
                                <td style={{ padding: "0px" }}>
                                  <p
                                    style={{
                                      fontSize: "13.3px",
                                      backgroundColor: "#f0f0f0",
                                      padding: "10px",
                                    }}
                                  >
                                    {res.origin}
                                  </p>
                                  {res?.destinations.map((item) => (
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        marginBottom: "-10px",
                                        padding: "10px",
                                        marginTop: "-15px",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => {
                                        setClickDestination(true);
                                        fetchCalcData(item.id);
                                        console.log("My calcId", item.id);
                                        localStorage.setItem("destId", item.id);
                                        fetchSingleClacData(item.id);
                                      }}
                                    >
                                      <p>{item.destination}</p>
                                      <p>{item.distance}</p>
                                    </div>
                                  ))}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <ul className="pagination justify-content-end">
                          {Array.from({
                            length: Math.ceil(users.length / usersPerPage),
                          }).map((_, index) => (
                            <li
                              key={index}
                              className={`page-item ${
                                index + 1 === currentPage ? "active" : ""
                              }`}
                            >
                              <button
                                onClick={() => paginate(index + 1)}
                                className="page-link"
                                style={{ alignItems: "center" }}
                              >
                                {index + 1}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Grid>
          </Grid>
        )}
      </Card>
    </div>
  );
};

export default UserList;
