import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { Grid, TextField } from "@mui/material";

const PartyUser = () => {
  const [partyUsers, setPartyUsers] = useState([]);
  const [originalPartyUsers, setOriginalPartyUsers] = useState([]);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [filterValue, setFilterValue] = useState("");
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = partyUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchPartyUsers();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [filterValue]);

  const handleFilter = () => {
    if (filterValue.trim() === '') {
      setPartyUsers(originalPartyUsers);
    } else {
      const filteredUsers = originalPartyUsers.filter(user => 
        user.party.businessName.toLowerCase().includes(filterValue.toLowerCase()) ||
        user.party.contactPerson.toLowerCase().includes(filterValue.toLowerCase())||
        user.party.mobileNumber.toString().toLowerCase().includes(filterValue.toLowerCase())
      );
      setPartyUsers(filteredUsers);
    }
  };

  const fetchPartyUsers = async () => {
    let employeeId = sessionStorage.getItem("employeeIdForPartyList");
    try {
      const response = await axios.get(
        environment.apiUrl + "party/all/" + employeeId,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
          },
        }
      );
      setPartyUsers(response.data);
      setOriginalPartyUsers(response.data)
    } catch (error) {
      console.error("Error fetching party users:", error);
    }
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "party/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then(
        (response) => toast.success("Successfully Deleted"),
        setPartyUsers((prevUsers) => prevUsers.filter((user) => user.id !== id))
      )
      .catch((error) => {
        toast.error("It can not be Deleted because its link with supervisor");
      });
  }

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/adminParty">Party</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#"> Party List</Link>
          </li>
        </ol>
      </div>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Party List</h4>
            <Link to="/adminParty">
              <button className="btn btn-primary" style={{ margin: "10px" }}>
                Back
              </button>
            </Link>
          </div>
          <div className="card-body">
          <Grid container>
              <Grid
                items
                xs={12}
                sm={12}
                md={6}
                style={{ marginBottom: "10px", marginTop: "-15px" }}
              >
                <TextField
                  variant="outlined"
                  placeholder="Search..."
                  fullWidth
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
              </Grid>
            </Grid>
            <div className="w-100 table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Serial No</th>
                    <th>Party SlNo</th>
                    <th>Business Name</th>
                    <th>Contact Person</th>
                    <th>Mobile Number</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers &&
                    currentUsers.map((res, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{res.party?.slNo}</td>
                        <td
                         
                        >
                          {res.party.businessName}
                        </td>
                        <td  style={{
                            color: res.party.isBlacklist ? "red" : "",
                            textDecoration:res.party.isBlacklist ?"line-through":"none"
                          }}> {res.party.contactPerson} </td>
                        <td>{res.party.mobileNumber} </td>
                        <td>
                          <div className="btn-group" role="group">
                            <button
                              onClick={() =>
                                history.replace("partyUser/" + res.party.id)
                              }
                              className="btn btn-primary"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => onDelete(res.party.id)}
                              style={{ marginLeft: "5px" }}
                              className="btn btn-danger"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <ul className="pagination justify-content-end">
                {Array.from({
                  length: Math.ceil(partyUsers.length / usersPerPage),
                }).map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      index + 1 === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      onClick={() => paginate(index + 1)}
                      className="page-link"
                      style={{ alignItems: "center" }}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PartyUser;
